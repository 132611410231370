import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import fileCollection from "../assets/images/file-collection.png";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

const SalesTaxSettings = () => {
  const { t } = useTranslation();
  const [isAddTaxModelOpen, setIsAddTaxModelOpen] = useState(false);
  const [isEditTaxForm, setIsEditTaxForm] = useState(false);
  const [error, setError] = useState([]);
  const [formData, setFormData] = useState({
    tax_name: "",
    tax_rate: "",
    charge_tax_by_default: "",
  });

  const addTaxModelStyles = {
    content: {
      width: "45%",
      height: "70%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "#6c5a5669",
    },
  };

  const handleAddSalesTax  = (e) => {
    setIsAddTaxModelOpen(true);
  };

  const handleApplyTaxes  = (e) => {
    //code
  };

  const handleChange = (e) => {
    //code
  };

  const saveSalesTax = (e) => {

  }

  const updateSalesTax = (e) => {
    
  }
  return (
    <>
    <ReactModal
        isOpen={isAddTaxModelOpen}
        onRequestClose={() => setIsAddTaxModelOpen(false)}
        contentLabel="Example Modal"
        style={addTaxModelStyles}
    >
        <div className="calendar-modal">
        <div className="close-h add">
            <h4>
            <strong>
                {isEditTaxForm ? "Update Sales Tax" : "Create New Sales Tax"}
            </strong>
            </h4>
            <button className="closeModal" onClick={() => setIsAddTaxModelOpen(false)}>
            X
            </button>
        </div>
        <br></br>
        <form name="studentProfile">
            <div className="row d-flex">
              <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                      <div className="formbold-input-flex">
                          <div>
                              <label htmlFor="tutor" className="formbold-form-label">
                                  Tax Name
                              </label>
                              <div>
                                  <input
                                  type="text"
                                  name="tax_name"
                                  className="form-control"
                                  value={formData.tax_name}
                                  onChange={(e) => handleChange(e.target.value)}
                                  />
                              </div>
                              <div className="pb-2">
                                  <small className="input-error-message">
                                  {error?.tax_name?.length ? error.tax_name[0] : <></>}
                                  </small>
                              </div>
                          </div>
                          <div>
                              <label htmlFor="tutor" className="formbold-form-label">
                                  Tax Rate
                              </label>
                              <div>
                                  <input
                                  type="number"
                                  name="tax_rate"
                                  className="form-control"
                                  value={formData.tax_rate}
                                  onChange={(e) => handleChange(e.target.value)}
                                  />
                              </div>
                              <div className="pb-2">
                                  <small className="input-error-message">
                                  {error?.tax_rate?.length ? error.tax_rate[0] : <></>}
                                  </small>
                              </div>
                          </div>
                      </div>
                      <div className="formbold-input-flex diff">
                          <div>
                              <label htmlFor="preferences" className="formbold-form-label m-0">
                                  {t("Make Default")}
                              </label>
                              <br />
                              <span class="m-o">This tax will automatically be included in the price for newly scheduled lessons/events.</span>
                              <div className="preference p-2" style={{ fontSize: "15px" }}>
                                  <input
                                      type="checkbox"
                                      name="charge_tax_by_default"
                                      onChange={handleChange}
                                      checked={
                                          formData.charge_tax_by_default === 1 ? true : false
                                      }
                                  />
                                  {t("Charge this tax by default")}
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr></hr>
                  <div className="formbold-form-btn-wrapper">
                    <div className="btn-end">
                      <Link className="cancel" onClick={() => setIsAddTaxModelOpen(false)}>
                        Cancel
                      </Link>
                      {isEditTaxForm && (
                        <button
                          className="cancel"
                          onClick={(e) => updateSalesTax(e)}
                        >
                          Save This & Future Events
                        </button>
                      )}
                      <button
                        className="formbold-btn"
                        onClick={(e) => saveSalesTax(e)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
              </div>
            </div>
        </form>
        </div>
    </ReactModal>

    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingSalesTax">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseSalesTax"
              aria-expanded="false"
              aria-controls="flush-collapseSalesTax"
            >
              <strong>{t("Sales Tax")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseSalesTax"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingSalesTax"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body text-center">
                <div className="py-3">
                    <div className="chart chart-xs">
                    <img src={fileCollection} style={{ width: '30%', height: '200px' }}></img>
                    </div>
                </div>
                <h4>
                    <strong>You don't have any sales tax set up yet</strong>
                </h4>
                <p style={{ textAlign: "center" }}>
                    Track sales tax charged to students (and paid on expenses). If you charge sales tax in your region, add the tax rates that you'll charge here.
                </p>
                <div className="formbold-form-btn-wrapper justify-content-center">
                    <button className="formbold-btn" onClick={handleAddSalesTax}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                        {t("Add New Sales Tax")}
                    </button>
                    <button className="formbold-btn" onClick={handleApplyTaxes}>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                        {t("Apply Taxes")}
                    </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
    
  );
};

export default SalesTaxSettings;
