import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUserDataContext } from "../../../contextApi/userDataContext";
import './css/TagInput.css';
import Select from "react-select";
import CurrencyService from "../../../services/currencyService";

const FamilyAccountSettings = ({ handleChange, formData, formSubmit, tags, setTags,
                                  chargeCategoryId, setChargeCategoryId,
                                  showDayOfMonth, setShowDayOfMonth, showSpecificDate, setShowSpecificDate,
                                  showFixedAmountFields, setShowFixedAmountFields, showPercentageAmountFields, 
                                  setShowPercentageAmountFields
 }) => {
  const { t } = useTranslation();
  const { fetchChargeCategory, allChargeCategory } = useUserDataContext();
  const [selectedChargeCategory, setSelectedChargeCategory] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const selectedCurrency = CurrencyService.getCurrency();

  useEffect(()=>{
    fetchChargeCategory();
  },[])

  useEffect(() => {
    const selected = allChargeCategory.find(e => e.id === chargeCategoryId);
    setSelectedChargeCategory(selected ? { value: selected.id, label: selected.chargecat_name } : null);
  }, [chargeCategoryId, allChargeCategory]);

  const handleBalanceDateChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    setShowDayOfMonth(value === "dayOfMonth");
    setShowSpecificDate(value === "specificDate");
  };

  const handleLatePaymentFeeChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    setShowFixedAmountFields(value === "fixed");
    setShowPercentageAmountFields(value === "percentage");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ';') {
      e.preventDefault();
      const newTag = inputValue.trim().replace(/;$/, '');
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  console.log('setChargeCategory 222: ',chargeCategoryId);

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <strong>{t("Family Account")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {/* Family Account Section */}
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Family Account")}</h3>
              </div>
              <div className="form-group">
                <label htmlFor="payment_methods" className="formbold-form-label">
                  {t("Payment Methods")} <span>Optional</span>
                </label>
                <br />
                <input
                  type="text"
                  id="payment_methods"
                  className="form-control"
                  name="payment_methods"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={t("Enter payment methods separated by semicolon or press the Enter key")}
                />
                <div className="tags-container">
                  { tags.length > 0 &&
                    tags.map((tag, index) => (
                      <div key={index} className="tag">
                        {tag}
                        <button type="button" onClick={() => handleRemoveTag(tag)}>
                          &times;
                        </button>
                      </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="balance_date" className="formbold-form-label">
                  {t("Default Balance Date")}
                </label>
                <div className="p-2">
                  <input
                    type="radio"
                    id="today"
                    name="balance_date"
                    value="today"
                    onChange={handleBalanceDateChange}
                    checked={formData?.balance_date === "today"}
                  />{" "}
                  {t("Today")}
                  <br />
                  <input
                    type="radio"
                    id="endOfMonth"
                    name="balance_date"
                    value="endOfMonth"
                    onChange={handleBalanceDateChange}
                    checked={formData?.balance_date === "endOfMonth"}
                  />{" "}
                  {t("End of month")}
                  <br />
                  <input
                    type="radio"
                    id="dayOfMonth"
                    name="balance_date"
                    value="dayOfMonth"
                    onChange={handleBalanceDateChange}
                    checked={formData?.balance_date === "dayOfMonth"}
                  />{" "}
                  {t("Day of month")}
                  <br />
                  <input
                    type="radio"
                    id="specificDate"
                    name="balance_date"
                    value="specificDate"
                    onChange={handleBalanceDateChange}
                    checked={formData?.balance_date === "specificDate"}
                  />{" "}
                  {t("Specific date")}
                  <br />
                </div>
                {showDayOfMonth && (
                  <div id="selectSpecificDate" className="mt-3">
                    <span>Select a day of the month between 1 and 31</span>
                    <input
                      type="text"
                      onChange={handleChange}
                      id="day_of_month"
                      name="day_of_month"
                      className="form-control"
                      value={formData.day_of_month}
                      placeholder={t("Select a day of the month between 1 and 31")}
                    />
                  </div>
                )}
                {showSpecificDate && (
                  <div id="selectSpecificDate" className="mt-3">
                    <span>Select a specific balance date</span>
                    <input
                      type="date"
                      onChange={handleChange}
                      id="specific_date"
                      name="specific_date"
                      value={formData.specific_date}
                      className="form-control"
                    />
                  </div>
                )}
              </div>

              {/* Invoice Settings Section */}
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Invoice Settings")}</h3>
              </div>
              <div className="form-group">
                <label htmlFor="late_payment_fee" className="formbold-form-label">
                  {t("Automatic Late Payment Fee")}
                </label>
                <div className="p-2">
                  <input
                    type="radio"
                    id="noFee"
                    name="late_payment_fee"
                    value="none"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "none"}
                  />{" "}
                  {t("None")}
                  <br />
                  <input
                    type="radio"
                    id="fixedAmount"
                    name="late_payment_fee"
                    value="fixed"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "fixed"}
                  />{" "}
                  {t(`Fixed amount ${selectedCurrency}`)}
                  <br />
                  <input
                    type="radio"
                    id="percentage"
                    name="late_payment_fee"
                    value="percentage"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "percentage"}
                  />{" "}
                  {t("Percentage (%)")}
                  <br />
                </div>
                {showFixedAmountFields && (
                  <div className="mt-3">
                    <span>Enter a late fee amount {selectedCurrency}</span>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="late_fee_amount"
                      className="form-control"
                      value={formData.late_fee_amount}
                    />
                    <br />
                  </div>
                )}
                {showPercentageAmountFields && (
                  <div className="mt-3">
                    <span>Enter a late fee percentage (%)</span>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="late_fee_percentage"
                      className="form-control"
                      value={formData.late_fee_percentage}
                    />
                    <br />
                  </div>
                )}
                {(showFixedAmountFields || showPercentageAmountFields) && (
                  <div className="mt-3">
                    <span>How many days after the due date should the late fee be applied?</span>
                    <input
                      type="number"
                      onChange={handleChange}
                      name="late_fee_apply_after_days"
                      className="form-control"
                      value={formData.late_fee_apply_after_days}
                    />
                    <br />
                    <span>Select a category to use for automatic late fees (optional)</span>
                    <Select 
                      onChange={(e)=>{setSelectedChargeCategory(e);setChargeCategoryId(e.value)}} 
                      isMulti={false} 
                      name="category_for_late_fees"
                      options={[...allChargeCategory.map((e)=>{return {value:e.id,label:e.chargecat_name}})]} 
                      value={selectedChargeCategory}
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Notifications & Reminders")}
                </label>
                <div className="p-2">
                  <input
                    type="checkbox"
                    id="sendSMS"
                    name="send_invoice_notification"
                    onChange={handleChange}
                    checked={formData?.send_invoice_notification === 1}

                  />{" "}
                  {t("Send SMS invoice notifications and invoice reminders")}
                  <br />
                  <input
                    type="checkbox"
                    id="overdueReminder"
                    name="send_over_due_invoice_reminder"
                    onChange={handleChange}
                    checked={formData?.send_over_due_invoice_reminder === 1}

                  />{" "}
                  {t("Send an overdue invoice reminder")}
                  <br />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="email_time_frame" className="formbold-form-label">
                  {t("Email Timeframe")}
                </label>
                <input
                  type="text"
                  onChange={handleChange}
                  id="email_time_frame"
                  name="email_time_frame"
                  className="form-control"
                  placeholder={t("Between 8:00 AM and 9:00 AM")}
                  value={formData.email_time_frame}
                />
              </div>

              {/* Invoice Formatting Section */}
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Invoice Formatting")}</h3>
              </div>
              <div className="form-group">
                <label htmlFor="invoice_name" className="formbold-form-label">
                  {t("Invoice Name")}
                </label>
                <input
                  type="text"
                  id="invoice_name"
                  onChange={handleChange}
                  name="invoice_name"
                  className="form-control"
                  placeholder={t("Enter invoice name")}
                  value={formData.invoice_name}

                />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  id="generate_invoice_number"
                  name="generate_invoice_number"
                  onChange={handleChange}
                  checked={formData?.generate_invoice_number === 1}
                />{" "}
                {t("Generate invoice number")}
              </div>
              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Negative Invoices")}
                </label>
                <div className="p-2">
                  <input
                    type="radio"
                    id="showZeroDue"
                    name="negative_invoices"
                    onChange={handleChange}
                    value="zero_due"
                    checked={formData?.negative_invoices === "zero_due"}
                  />{" "}
                  {t(`Show as ${selectedCurrency} 0.00 due`)}
                  <br />
                  <input
                    type="radio"
                    id="balanceRemaining"
                    name="negative_invoices"
                    onChange={handleChange}
                    value="balance_remaining"
                    cxhecked={formData?.negative_invoices === "balance_remaining"}
                  />{" "}
                  {t("Show as 'Balance Remaining'")}
                  <br />
                </div>
              </div>
              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyAccountSettings;
