import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyService from "../../../services/currencyService";

const PolicySettings = ({ handleChange, formData, formSubmit }) => {
  const { t } = useTranslation();
  const selectedCurrency = CurrencyService.getCurrency();

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingPolicy">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapsePolicy"
              aria-expanded="false"
              aria-controls="flush-collapsePolicy"
            >
              <strong>{t("Policy Settings")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapsePolicy"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingPolicy"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Cancellation Policy")}</h3>
              </div>
           
              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Event Cancellation in Student Portal")}
                </label>
                <br />
                <div className="preference">
                    <input
                    type="checkbox"
                    name="allow_event_cancellation"
                    onChange={handleChange}
                    checked={
                        formData.allow_event_cancellation === 1 ? true : false}
                    />{" "}
                    {t("Allow event cancellation on this platform")}
                </div>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Advance cancellation up to (hours)")}
                </label>
                <input
                  type="number"
                  name="advance_cancellation_hours"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.advance_cancellation_hours}
                />
                <div className="preference p-1">
                    <input
                    type="checkbox"
                    name="log_in_app_notification_when_cancellation_occurs"
                    onChange={handleChange}
                    checked={
                        formData.log_in_app_notification_when_cancellation_occurs === 1 ? true : false}
                    />{" "}
                    {t("Log in-app notification when a cancellation occurs")}
                </div>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("If an event is cancelled before a deadline")}
                </label>
                <select
                  name="event_is_cancelled_before_deadline"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.event_is_cancelled_before_deadline}
                >
                  <option value="mark_absent_bill_and_give_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                  <option value="mark_absent_notice_given">{t('Mark as "Absent, Notice Given"')}</option>
                  <option value="remove_student_from_attendee_list">{t("Remove student from attendee list")}</option>
                </select>
                <div className="preference p-1">
                    <input
                    type="checkbox"
                    name="convert_event_to_make_up_slot_before_deadline"
                    onChange={handleChange}
                    checked={
                        formData.convert_event_to_make_up_slot_before_deadline === 1 ? true : false}
                    />{" "}
                    {t("Convert event to make-up slot")}
                </div>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("If an event is cancelled after a deadline")}
                </label>
                <select
                  name="event_is_cancelled_after_deadline"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.event_is_cancelled_after_deadline}
                >
                  <option value="mark_absent_bill_and_give_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                  <option value="mark_absent_billable_and_no_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                </select>
                <div className="preference p-1">
                    <input
                    type="checkbox"
                    name="convert_event_to_make_up_slot_after_deadline"
                    onChange={handleChange}
                    checked={
                        formData.convert_event_to_make_up_slot_after_deadline === 1 ? true : false}
                    />{" "}
                    {t("Convert event to make-up slot")}
                </div>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Policy Text")}
                </label>
                <textarea
                  name="policy_text"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.policy_text}
                ></textarea>
              </div>

              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Sign-Up Policy")}</h3>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Event sign-up in Student Portal")}
                </label>
                <br />
                <div className="preference">
                    <input
                    type="checkbox"
                    name="allow_event_sign_up"
                    onChange={handleChange}
                    checked={formData.allow_event_sign_up === 1 ? true : false} 
                    />{" "}
                    {t("Allow event sign-up on this platform")}
                </div>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Allow prior Sign-Up up to (hours)")}
                </label>
                <input
                  type="number"
                  name="allow_sign_up_to_hours"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.allow_sign_up_to_hours}
                />
                <div className="preference p-1">
                    <input
                    type="checkbox"
                    name="log_in_app_notification_when_a_sign_up_occurs"
                    onChange={handleChange}
                    checked={
                        formData.log_in_app_notification_when_a_sign_up_occurs === 1 ? true : false}
                    />{" "}
                    {t("Log in-app notification when a sign-up occurs")}
                </div>
              </div>

              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Make-Up Credit Policy")}</h3>
              </div>

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Make-Up Credit Category")}
                </label>
                <select
                  name="make_up_credit_category"
                  className="form-control"
                  onChange={handleChange}
                  value={formData.make_up_credit_category}
                >
                  <option value="no_change_use_original_category">{t("No Change - Use Original Event Category")}</option>
                  <option value="group_lesson">{t("Group Lesson")}</option>
                  <option value="vacation">{t("Vacation")}</option>
                  <option value="lesson">{t("Lesson")}</option>
                </select>
              </div>

              <div className="form-group d-flex">
                <div className="preference p-3">
                    <input
                    type="checkbox"
                    name="limit_make_up_credits"
                    onChange={handleChange}
                    checked={formData.limit_make_up_credits === 1 ? true : false}
                    />{" "}
                    {t("Limit number of make-up credits")}
                </div>
                <div className="preference p-3">
                    <input
                    type="checkbox"
                    name="expire_unused_make_up_credits"
                    onChange={handleChange}
                    checked={formData.expire_unused_make_up_credits === 1 ? true : false}
                    />{" "}
                    {t("Expire unused make-up credits")}
                </div>

              </div>

              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicySettings;
