import axios from "axios";
import { API_URL } from "../utils/config";

export const getFamilyAccounts = async () =>{
  const config ={
    method : "GET",
    url: `${API_URL}family-accounts`
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
}

export const createInvoice= async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}create-invoice`,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
};

export const getFamilyAccountsDetails= async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}family-account/`+id
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
};

export const saveTransaction= async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}create-transaction`,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
};

export const updateTransaction= async (data,id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}update-transaction/`+id,
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const disableAutoInvoicesTransaction= async (id, data) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}disable-auto-invoicing/`+id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
};

export const updatePaidStatus = async (data,id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}invoice-paid/`+id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  })
  .catch((error) => {
    return error;
  });
};

export const updateArchiveStatus = async (data,id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}invoice-archive/` + id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
};

export const updateVoidStatus = async (data,id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}invoice-void/` + id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
};

export const getTransactionById = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}transaction/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
};

export const enableAutoInoviceById = async ( data,id ) =>{
  const config = {
    method: "POST",
    url: `${API_URL}configure-auto-invoicing/` + id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getInvoicePdf = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}invoice-pdf/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getIncomingInvoice = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}upcoming-latest-invoice/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getDownloadPdf = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}download-invoice-pdf/` + id,
    responseType: "blob"
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const deleteInvoiceById = async (id) => {
  const config = {
    method: "DELETE",
    url: `${API_URL}delete-invoice/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getInvoiceById = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}invoice/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const sendEmail = async ( data,id ) =>{
  const config = {
    method: "POST",
    url: `${API_URL}email-invoice/` + id,
    data: data
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}


export const getAllInvoiceByDate = async (date_from,date_to) => {
  const config = {
    method: "GET",
    url: `${API_URL}invoices?date_from=${date_from}&date_to=${date_to}`
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const deleteTransactionById = async (id ) =>{
  const config = {
    method: "DELETE",
    url: `${API_URL}delete-transaction/` + id
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getPrepaidBalance = async() =>{
  const config = {
    method: "GET",
    url: `${API_URL}prepaid-balance`
  };
  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

 export const getOwedBalance = async (id = null, date = null) => {
  let config = {
    method: "GET",
    url: `${API_URL}owed-balance`
  };

  if (id != null && date != null) {
    config.url = `${API_URL}owed-balance/${id}?date=${formatDate(date)}`;
  } else if (id != null) {
    config.url = `${API_URL}owed-balance/${id}`;
  } else if (date != null) {
    config.url = `${API_URL}owed-balance/?date=${formatDate(date)}`;
  }

  return await axios(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error;
  });
};
