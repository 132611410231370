import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput";


export const NewNonTutoringEventModal = ({ 
    isOpen,
    afterOpenModal,
    closeModal,
    style,
    contentLabel,
    isEditForm,
    event_name,
    set_event_name,
    event_tutor,
    set_event_tutor,
    allTutors,
    eventcat_id,
    set_eventcat_id,
    categoriesList,
    // eventcat_name,
    location_id,
    set_location_id,
    allLocation,
    start_date,
    formatDate,
    handleNewEventChange,
    set_start_date,
    start_time,
    set_start_time,
    duration,
    setDuration,
    calculateEndDateTimeByDuration,
    disableTimeDuration,
    errors,
    event_all_day,
    set_event_all_day,
    event_recurring,
    set_event_recurring,
    event_frequency,
    set_event_frequency,
    event_repeat_on,
    set_event_repeat_on,
    event_frequency_val,
    set_event_frequency_val,
    event_repeat_until,
    set_event_repeat_until,
    event_repeat_indefinitely,
    set_event_repeat_indefinitely,
    event_public_desc,
    set_event_public_desc,
    pubdesc_on_calendar,
    set_pubdesc_on_calendar,
    saveAllEvents,
    saveOneEvents }) => (

    <ReactModal
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={style}
          contentLabel={contentLabel}
        >
          <div className="calendar-modal">
            <div className="close-h add">
              <h4>
                <strong>
                  {isEditForm
                    ? "Edit Calendar Event"
                    : "New Non-Tutoring Event"}
                </strong>
              </h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <br></br>
            <form name="studentProfile">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex diff">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Title
                        </label>
                        <div>
                          <input
                            type="text"
                            name="event_name"
                            className="form-control"
                            value={event_name}
                            onChange={(e) => set_event_name(e.target.value)}
                          />
                          {errors.event_name && <small className="input-error-message">{errors.event_name[0]}</small>}
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label htmlFor="tutor" className="formbold-form-label">
                          Tutor
                        </label>
                        <div>
                          <select
                            name="tutor"
                            className="form-control"
                            value={event_tutor}
                            onChange={(e) => set_event_tutor(e.target.value)}
                            id="tutor"
                          >
                            <option value="">Choose</option>
                            {allTutors && allTutors.length > 0 ? (
                              allTutors.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))
                            ) : (
                              <option value="">No tutor available</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="category"
                          className="formbold-form-label"
                        >
                          Category
                        </label>
                        <br></br>

                        <select
                          name="category"
                          className="form-control"
                          value={eventcat_id}
                          onChange={(e) => set_eventcat_id(e.target.value)}
                          id="category"
                        >
                          <option value={""}>Choose</option>
                          {categoriesList?.map((e) => {
                            return (
                              <option value={e?.id}>{e?.eventcat_name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="location"
                          className="formbold-form-label"
                        >
                          Location
                        </label>
                        <br></br>

                        <select
                          name="location"
                          className="form-control"
                          value={location_id}
                          onChange={(e) => set_location_id(e.target.value)}
                        >
                          <option value={""}>Choose</option>
                          {allLocation?.map((e) => {
                            return (
                              <option value={e?.id}>{e?.eventloc_name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="start_date"
                            className="formbold-form-label"
                          >
                            Date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            value={formatDate(start_date)}
                            className="form-control"
                            // value={formData.phone}
                            onChange={handleNewEventChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label htmlFor="time" className="formbold-form-label">
                          Time
                        </label>
                        <br></br>

                        <input
                          type="time"
                          name="time"
                          className="form-control"
                          value={start_time}
                          onChange={(e) => set_start_time(e.target.value)}
                        />
                        {errors.start_time && <small className="input-error-message">{errors.start_time[0]}</small>}
                      </div>
                      <div style={{ position: "relative" }}>
                        <label
                          htmlFor="duration"
                          className="formbold-form-label"
                        >
                          Duration
                        </label>
                        <br></br>

                        <input
                          type="text"
                          name="duration"
                          className="form-control"
                          placeholder=""
                          value={duration}
                          onChange={(e) => {
                            setDuration(e.target.value);
                            calculateEndDateTimeByDuration(
                              e.target.value != "" ? e.target.value : 0
                            );
                          }}
                          disabled={disableTimeDuration}
                        />
                        <span
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "60%",
                              // transform: "translateY(-120%)",
                            }}
                          >
                            Minutes
                          </span>
                        {errors.end_time && <small className="input-error-message">{errors.end_time[0]}</small>}
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div></div>
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="all_day"
                            value="All Day"
                            checked={event_all_day}
                            onChange={(e) =>
                              set_event_all_day(e.target.checked)
                            }
                          />
                          All Day
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <div
                          className="preference"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="event_repeats"
                            value="This event repeats"
                            checked={event_recurring}
                            onChange={(e) =>
                              set_event_recurring(e.target.checked)
                            }
                          />
                          This event repeats
                        </div>
                      </div>
                    </div>
                    {event_recurring && (
                      <>
                        <div className="recurring">
                          <div className="recurring-head">
                            <i class="fa fa-undo" aria-hidden="true"></i>{" "}
                            <strong>Recurring Event</strong>
                          </div>

                          <div className="formbold-input-flex diff">
                            <div>
                              <div>
                                <label
                                  htmlFor="frequency"
                                  className="formbold-form-label"
                                >
                                  Frequency
                                </label>
                              </div>
                              <div className="input-radio">
                                <input
                                  type="radio"
                                  value="Daily"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Daily"}
                                ></input>
                                Daily
                                <input
                                  type="radio"
                                  value="Weekly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Weekly"}
                                ></input>
                                Weekly
                                <input
                                  type="radio"
                                  value="Monthly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Monthly"}
                                ></input>
                                Monthly
                                <input
                                  type="radio"
                                  value="Yearly"
                                  name="frequency"
                                  onChange={(e) =>
                                    set_event_frequency(e.target.value)
                                  }
                                  checked={event_frequency === "Yearly"}
                                ></input>
                                Yearly
                              </div>
                              {event_frequency == "Daily" && (
                                <DayTabInput
                                  values={event_repeat_on}
                                  setDaysValue={set_event_repeat_on}
                                />
                              )}
                            </div>
                          </div>
                          {event_frequency != "Daily" && (
                            <div className="formbold-input-flex align-items-end">
                              <div>
                                <label
                                  htmlFor="time"
                                  className="formbold-form-label"
                                >
                                  Every
                                </label>
                                <br></br>
                                <div style={{ position: "relative" }}>
                                  <input
                                    type="text"
                                    name="every"
                                    className="form-control"
                                    style={{
                                      paddingLeft: "25px",
                                      paddingRight: "70px",
                                    }}
                                    value={event_frequency_val}
                                    min={1}
                                    max={100}
                                    onChange={(e) =>
                                      set_event_frequency_val(e.target.value)
                                    }
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                    }}
                                  >
                                    {event_frequency === "Daily" ||
                                    event_frequency === "Weekly"
                                      ? "Weeks"
                                      : event_frequency}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                          {!event_repeat_indefinitely && (
                            <div>
                              <label
                                htmlFor="time"
                                className="formbold-form-label"
                              >
                                Repeat Until
                              </label>
                              <br></br>
                              <input
                                type="date"
                                name="repeat_until"
                                className="form-control"
                                value={event_repeat_until}
                                onChange={(e) =>
                                  set_event_repeat_until(
                                    formatDate(e.target.value)
                                  )
                                }
                              />{" "}
                            </div>
                          )}

                          <div className="formbold-input-flex">
                            <div>
                              <div
                                className="preference"
                                style={{ fontSize: "15px" }}
                              >
                                <input
                                  type="checkbox"
                                  name="repeats_indefinitely"
                                  value=""
                                  onChange={(e) =>
                                    set_event_repeat_indefinitely(
                                      e.target.checked
                                    )
                                  }
                                  checked={event_repeat_indefinitely}
                                />
                                Repeat indefinitely
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="formbold-input-flex diff">
                      <div>
                        <label
                          htmlFor="public_desc"
                          className="formbold-form-label"
                        >
                          Public Description <span>Optional</span>
                        </label>

                        <textarea
                          name="public_desc"
                          value={event_public_desc}
                          onChange={(e) =>
                            set_event_public_desc(e.target.value)
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="formbold-input-flex diff">
                      <div>
                        <div
                          className="public_desc_on_calendar"
                          style={{ fontSize: "15px" }}
                        >
                          <input
                            type="checkbox"
                            name="public_desc_on_calendar"
                            value="This event repeats"
                            checked={pubdesc_on_calendar}
                            onChange={(e) =>
                              set_pubdesc_on_calendar(e.target.checked)
                            }
                          />{" "}
                          Show public description directly on calendar
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel" onClick={closeModal}>
                      Cancel
                    </Link>
                    {isEditForm && event_recurring && (
                      <button
                        className="cancel"
                        onClick={(e) => saveAllEvents(e)}
                      >
                        Save This & Future Events
                      </button>
                    )}
                    <button
                      className="formbold-btn"
                      onClick={(e) => saveOneEvents(e)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>
)