// AvailabilityForm.js
import React from 'react';

/**
 * AvailabilityForm component renders a form for adding or editing availability details.
 * @param {Object} props - Component props.
 * @param {Object} props.availData - Current availability data for editing.
 * @param {Function} props.handleAvailChange - Function to handle input change in the form fields.
 * @param {Function} props.handleAvailChangePopup - Function to handle input change in a popup for availability.
 * @param {Function} props.formAvailSubmit - Function to handle form submission.
 * @param {Object} props.error - Error object containing validation errors.
 * @param {Function} props.setAvailFlag - Function to toggle the availability form visibility.
 */
const AvailabilityForm = ({
  availData,
  handleAvailChange,
  handleAvailChangePopup,
  formAvailSubmit,
  error,
  setAvailFlag
}) => (
  <div className="availablity">
    {/* Add Availability Label */}
    <div className="formbold-input-flex diff">
      <div>
        <label htmlFor="availability" className="formbold-form-label">Add Availability</label>
      </div>
    </div>

    {/* Days Selection */}
    <div className="formbold-input-flex diff">
      <div>
        <label htmlFor="days" className="formbold-form-label">Days</label>
        {/* Display error message if there are errors in days */}
        <small className="input-error-message">{error?.days?.length ? error?.days[0] : <></>}</small>
        <div className="studentStatus">
          {/* Render checkboxes for each day */}
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day}>
              <input type="checkbox" className="status" name={day.toLowerCase()} value={day} onChange={handleAvailChange} /> {day}
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Start Date and End Date Inputs */}
    <div className="formbold-input-flex">
      <div>
        <label htmlFor="start_date" className="formbold-form-label">Start Date <span>Optional</span></label>
        <input type="date" name="start_date" className="form-control" onChange={handleAvailChange} />
      </div>
      <div>
        <label htmlFor="end_date" className="formbold-form-label">End Date <span>Optional</span></label>
        <input type="date" name="end_date" className="form-control" onChange={handleAvailChange} />
      </div>
    </div>

    {/* Display error message if there are errors in end_date */}
    <small className="input-error-message">{error?.end_date?.length ? error?.end_date[0] : <></>}</small>

    {/* Start Time and End Time Inputs */}
    <div className="formbold-input-flex">
      <div>
        <label htmlFor="start_time" className="formbold-form-label">Start Time</label>
        <input type="time" name="start_time" className="form-control" onChange={handleAvailChange} />
      </div>
      <div>
        <label htmlFor="end_time" className="formbold-form-label">End Time</label>
        <input type="time" name="end_time" className="form-control" onChange={handleAvailChange} />
      </div>
    </div>

    {/* Note Textarea */}
    <div className="formbold-input-flex diff">
      <div>
        <label htmlFor="note" className="formbold-form-label">Note <span>Optional</span></label>
        <textarea name="note" className="form-control" onChange={handleAvailChange} />
      </div>
    </div>

    {/* Form Buttons */}
    <div className="formbold-form-btn-wrapper justify-content-end">
      <div className="btn-end">
        {/* Cancel button */}
        <a className="cancel" onClick={() => setAvailFlag(false)}>Cancel</a>
        {/* Save button */}
        <button className="formbold-btn" onClick={formAvailSubmit}>Save</button>
      </div>
    </div>
  </div>
);

export default AvailabilityForm;
