// ReusableModal.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

// Component to update availability with a modal
const UpdateAvailability = ({
  isOpen,                   // Boolean to control modal open state
  afterOpenModal,           // Callback after modal is opened
  closeModal,               // Callback to close the modal
  style,                    // Custom styles for the modal
  contentLabel,             // Label for accessibility
  title,                    // Modal title
  selectedDays,             // Array of selected days
  error,                    // Object containing error messages
  availData,                // Object containing availability data
  handleAvailChangePopup,   // Handler for changes in availability form
  updateAvailability,       // Callback to submit the availability form
}) => {
  // Days of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={style}
      contentLabel={contentLabel}
    >
      <div className="mypreference-modal">
        <div className="close-h">
          <h4>{title}</h4>
          <button className="closeModal" onClick={closeModal}>
            X
          </button>
        </div>
        <form name="studentProfile">
          <div className="row d-flex">
            <div className="col-xl-12 col-xxl-12">
              <div className="formbold-form-step-1 active">
                {/* Days selection */}
                <div className="formbold-input-flex diff">
                  <div>
                    <div>
                      <label htmlFor="days" className="formbold-form-label">
                        Days
                      </label>
                    </div>
                    <small className="input-error-message">
                      {error?.days?.length ? error?.days[0] : null}
                    </small>
                    <div className="studentStatus">
                      {daysOfWeek.map((day) => (
                        <div key={day}>
                          <input
                            type="checkbox"
                            className="status"
                            name={day.toLowerCase()}
                            value={day}
                            onChange={handleAvailChangePopup}
                            checked={selectedDays && selectedDays.includes(day)
                                  ? true
                                  : false}
                          />
                          {day}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Date selection */}
                <div className="formbold-input-flex">
                  <div>
                    <label htmlFor="start_date" className="formbold-form-label">
                      Start Date <span>Optional</span>
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      className="form-control"
                      value={availData.start_date}
                      onChange={handleAvailChangePopup}
                    />
                  </div>
                  <div>
                    <label htmlFor="end_date" className="formbold-form-label">
                      End Date <span>Optional</span>
                    </label>
                    <input
                      type="date"
                      name="end_date"
                      className="form-control"
                      value={availData.end_date}
                      onChange={handleAvailChangePopup}
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {error?.end_date?.length ? error?.end_date[0] : null}
                </small>

                {/* Time selection */}
                <div className="formbold-input-flex">
                  <div>
                    <label htmlFor="start_time" className="formbold-form-label">
                      Start Time
                    </label>
                    <input
                      type="time"
                      name="start_time"
                      className="form-control"
                      value={availData.start_time}
                      onChange={handleAvailChangePopup}
                    />
                  </div>
                  <div>
                    <label htmlFor="end_time" className="formbold-form-label">
                      End Time
                    </label>
                    <input
                      type="time"
                      name="end_time"
                      className="form-control"
                      value={availData.end_time}
                      onChange={handleAvailChangePopup}
                    />
                  </div>
                </div>

                {/* Note section */}
                <div className="formbold-input-flex diff">
                  <div>
                    <label htmlFor="note" className="formbold-form-label">
                      Note <span>Optional</span>
                    </label>
                    <textarea
                      name="note"
                      className="form-control"
                      value={availData.note}
                      onChange={handleAvailChangePopup}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="formbold-form-btn-wrapper">
              <div className="btn-end">
                <Link className="cancel" onClick={closeModal}>
                  Cancel
                </Link>
                <button className="formbold-btn" onClick={updateAvailability}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};

export default UpdateAvailability;
