import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import LanguageOption from "../LanguageOption.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { validateDomainName } from "../../services/loginService.js";
import "./style.css";
import { checkAuthAndRedirect } from '../../utils/helper.js';
import useGlobalSettings from "../../hooks/useGlobalSettings.js";

const DomainRegister = () => {

  const { t } = useTranslation();
  const { getSetting } = useGlobalSettings();
  const { setIsDarkMode, centralPortalDomain, getAndSetDomainName} = useUserDataContext();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [domain, setDomain] = useState ("");
  const [errorMessage, setErrorMessage] = useState("");
  console.log('expectedDomain Domain name page ');

  const handleDomainChange = async () => {
    var fullDomain = `${domain}.${centralPortalDomain}`;
    const data = {
      domain: `${fullDomain}`,
    };
    try {
      const response = await validateDomainName(data);
      if (response && response.success) {
        return response.success;
      } else {
        setErrorMessage(response.data.domain);
        return false;

      }
    } catch (error) {
      console.error("An error occurred:", error);
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
        const domainExists = await handleDomainChange();
        if(domainExists) {
          window.location.href = `${process.env.REACT_APP_PROTOCOL}://${domain}.${centralPortalDomain}/signin`; 
        }
      
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage('An error occurred. Please try again later.');
    } 
  };
  
  const multiLangHandler = (e) => {
    i18next.changeLanguage(e.target.value);
  };

  useEffect(() => {
    document?.documentElement?.setAttribute("data-theme", "light");
    setIsDarkMode(false);
    getAndSetDomainName();
    checkAuthAndRedirect(navigate,'DomainName', getSetting("centralPortalDomain"), getSetting('authToken'));
  },[]);

  return (
    <div className="d-md-flex justify-content-center align-items-center h-100 primary-bg">
      <ToastContainer />
      <div className="contents">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
            {/* <LanguageOption onChange={(e) => multiLangHandler(e)} /> */}
              <div className="form-block mx-auto">
                <div className="text-center mb-5">
                  <h3>
                    <strong>{t("TutorPad")}</strong>
                  </h3>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <form>
                <div className="form-group last mb-3">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control domain"
                        placeholder={t("domain test")}
                        value = {domain}
                        name="domain"
                        onChange={(e) =>
                            setDomain(e.target.value)
                          }
                          // style={{
                          //   '::placeholder': {
                          //     color: '#ccc',
                          //   },
                          // }} 
                      />
                      <span style={{ fontSize: "16px" , paddingLeft:"10px" }}>{centralPortalDomain}</span>
                    </div>
                    <small className="input-error-message">
                      {error?.domain?.length ? error.domain[0] : <></>}
                    </small>
                  </div>

                  <input
                    type="button"
                    value={t("Submit")}
                    className="btn btn-block btn-primary"
                    onClick={handleSubmit}
                  />
                </form>
                <br></br>
                {t("don't have an account?")}
                <Link to="/"> {t("register here")}</Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainRegister;
