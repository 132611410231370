import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import MiniSidebar from "../sidebar/MiniSidebar.js";
import Sidebar from "../sidebar/Sidebar.js";
import TopBar from "../sidebar/TopBar.js";
import LanguageOption from "../LanguageOption.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useTokenStorage } from '../../utils/helper.js';
import { AuthContext } from '../registerLogin/AuthContext.js';
import BusinessDashboard from './BusinessDashboard.js';
import TutorDashboard from './TutorDashboard.js';
import StudentDashboard from './StudentDashboard.js';
import useGlobalSettings from "../../hooks/useGlobalSettings.js";

const Dashboard = () => {
  const { sidebarToggle, getDashboardData, dashboardData } = useUserDataContext();
  const { role } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation()
  const storeToken = useTokenStorage();
  const { getSetting } = useGlobalSettings();

  useEffect(() => {
    //get token and domain from url and set it into localstorage
    getTokenData();
    getDashboardData();
  }, []);

  const getTokenData = async () => {

    // Get hash fragment from URL //use params
    const hash = window.location.hash;

    // Check if hash fragment is empty or doesn't contain parameters
    if (!hash || hash.indexOf('#') === -1) {
      const token = getSetting("authToken");
      if (!token) {
        navigate("/signin");
      }else{
        return; // Exit the function early if parameters are not present
      } 
    }

    // Remove the '#' character
    const hashWithoutSharp = hash.slice(1);

    // Split the hash fragment into token and domain
    const [encodedToken, encodedDomain, encodedRole] = hashWithoutSharp.split('#');

    // Decode the encoded values
    var token = decodeURIComponent(encodedToken);
    var domain = decodeURIComponent(encodedDomain);
    var role = decodeURIComponent(encodedRole);

    //store token in localstorage
    storeToken(token, domain, role);

    // Optionally, you may want to clear the hash fragment after reading it
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main main-content">
      
        <TopBar />

        <main className="content">
          { role === `${process.env.REACT_APP_BUSINESS_ROLE}` ?
            <BusinessDashboard dashboardData={dashboardData} accountType='business'/> : 
            role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` ?  
            <TutorDashboard dashboardData={dashboardData} accountType='tutor'/> : 
            role === `${process.env.REACT_APP_TUTOR_ROLE}` ?  
            <TutorDashboard dashboardData={dashboardData} accountType='tutor'/> : 
            role === `${process.env.REACT_APP_STUDENT_ROLE}` ?  
            <StudentDashboard dashboardData={dashboardData} accountType='student'/> : 
            'User Not Found!'
          }
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
