import axios from "axios";
import { API_URL } from "../utils/config";

export const createEvents = async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}create-event`,
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const updateEvents = async (data,id, occurrence_date) => {
  console.log("occurrence_date from update api ------------", occurrence_date);

  const config = {
    method: "PATCH",
    url: `${API_URL}update-event/`+id,
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const cloneEvents = async (data, occurrence_date) => {
  console.log("occurrence_date from clone ------------", occurrence_date);
  const config = {
    method: "POST",
    url: `${API_URL}clone-event`,
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteEvents = async (data,id) => {
  const config = {
    method: "PATCH",
    url: `${API_URL}delete-event/`+id,
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllStudents = async () =>{
  const config = {
    method: "GET",
    url: `${API_URL}get-students`
  };
  return await axios(config).then((response) =>{
    return response.data;
  }).catch((error) => {
    return error;
  });
}

export const getStudentsByTutorId = async (id) => {
  const config = {
    method: "GET",
    url: `${API_URL}assigned-students/`+id
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getEventDetailsById = async (id, occurrence_date) => {
  console.log("occurrence_date from Edit api ------------", occurrence_date);
  const config = {
    method: "GET",
    url: `${API_URL}event/`+id
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};


export const getAttendanceTypes = async () => {
  const config = {
    method: "GET",
    url: `${API_URL}astypes`
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};


export const updateTakeAttendanceEvents = async (data) => {
  const config = {
    method: "POST",
    url: `${API_URL}take-attendance`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data
  };
  return await axios(config).then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getParentDetailsList = async (name) => {
  const config = {
    method: "GET",
    url: `${API_URL}family-accounts/`+name
  };
  return await axios(config)
  .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getNotes = async (occurrence_id, student_id) => {
  const config = {
    method: "GET",
    url: `${API_URL}student-attendance/`+occurrence_id+`/`+student_id,
  };
  return await axios(config)
    .then((response) => {
      console.log("response from notes", response);
      
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching notes:", error);
      return error;
    });
};
