import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../../../../utils/config.js";
import Select from "react-select";
import { settings } from "react-icons-kit/feather/settings";
import "../../assets/css/style.css";
import {
  createEvents,
  getStudentsByTutorId,
  getEventDetailsById,
  updateEvents,
  deleteEvents,
  cloneEvents,
  getAllStudents,
} from "../../../../services/calenderService.js";

import { DeleteEventModal } from "../modal/DeleteEventModal.js";
import { NewEventModal } from "../modal/NewEventModal.js";
import { StudentEventModal } from "../modal/StudentEventModal.js";
import MiniSidebar from "../../../sidebar/MiniSidebar.js";
import Sidebar from "../../../sidebar/Sidebar.js";
import TopBar from "../../../sidebar/TopBar.js";
import { useUserDataContext } from "../../../../contextApi/userDataContext.js";
import OptionBox from "../../../form/option-box/OptionBox.js";
import DayTabInput from "../../../form/day-tab-input/DayTabInput.js";
import { getCategories } from "../../../../services/categoriesService.js";
import { CloneEventModal } from "../modal/CloneEventModal.js";
import { NewNonTutoringEventModal } from "../modal/NewNonTutoringEventModal.js";
import { QuickAddLessonModal } from "../modal/QuickAddLessonModal.js";
import { AddEventModal } from "../modal/AddEventModal.js";
import { EventModal } from "../modal/EventModal.js";
import CurrencyService from "../../../../services/currencyService.js";
import { AuthContext } from '../../../registerLogin/AuthContext.js';
import useGlobalSettings from "../../../../hooks/useGlobalSettings.js";

const customStyles = {
  content: {
    background: "black",
    width: "25%",
    minHeight: "35%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#6c5a5669",
  },
};
const addEventStyles = {
  content: {
    width: "25%",
    height: "auto",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#6c5a5669",
  },
};
const quickAddLessonStyles = {
  content: {
    width: "45%",
    height: "90%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#6c5a5669",
  },
};
const Calendars = () => {
  const { getSetting } = useGlobalSettings();
  const { role } = useContext(AuthContext);
  const {
    sidebarToggle,
    userId,
    userData,
    token,
    getTutor,
    allTutors,
    fetchEvent,
    allEvents,
    studentData,
    fetchStudentData,
    fetchStudentGroupData,
    studentGroupData,
    fetchLocation,
    allLocation,
    isDarkMode,
  } = useUserDataContext();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [eventData, setEventData] = useState({});
  const [eventTitle, setEventTitle] = useState({});
  const [eventDesc, setEventDesc] = useState({});
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDuration, setEventDuration] = useState("");
  const [tutorName, setTutorName] = useState("");
  const [takeAttendance, setTakeAttendance] = useState("");
  const [tutorId, setTutorId] = useState("");
  const [addEvent, setAddEvent] = useState({});
  const [eventRepeats, setEventRepeats] = useState(false);
  const [repeatsIndefinitely, setRepeatsIndefinitely] = useState(true);
  const [error, setError] = useState({});
  const [allDay, setAllDay] = useState(false);
  const [disableTimeDuration, setDisableTimeDuration] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Daily");
  const [showSubstituteTutor, setShowSubstituteTutor] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [everyWeeks, setEveryWeeks] = useState(1);
  const [selectedTutor, setSelectedTutor] = useState("");
  const [visibleRange, setVisibleRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [studentsList, setStudentsList] = useState([]);
  const [allStudentsList, setAllStudentsList] = useState([]);
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [isEditForm, setIsEditForm] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [event_attendees, set_event_attendees] = useState([]);
  const [location_id, set_location_id] = useState("");
  const [is_public, set_is_public] = useState("1");
  const [event_recurring, set_event_recurring] = useState(false);
  const [event_frequency, set_event_frequency] = useState("Daily");
  const [event_repeat_on, set_event_repeat_on] = useState([
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]);
  const [event_frequency_val, set_event_frequency_val] = useState("1");
  const [event_repeat_indefinitely, set_event_repeat_indefinitely] =
    useState(true);
  const [event_repeat_until, set_event_repeat_until] = useState("");
  const [require_makeup_credits, set_require_makeup_credits] = useState(false);
  const [event_public_desc, set_event_public_desc] = useState("");
  const [pubdesc_on_calendar, set_pubdesc_on_calendar] = useState(false);
  const [event_private_desc, set_event_private_desc] = useState("");
  const [allow_registration, set_allow_registration] = useState(false);
  const [registration_max, set_registration_max] = useState(0);
  const [student_pricing, set_student_pricing] = useState("default");
  const [student_pricing_val, set_student_pricing_val] = useState(0);
  const [event_all_day, set_event_all_day] = useState(false);
  const [eventtype_id, set_eventtype_id] = useState("1");
  const [eventcat_id, set_eventcat_id] = useState("");
  const [event_subtutor, set_event_subtutor] = useState("");
  const [event_name, set_event_name] = useState("");
  const [event_tutor, set_event_tutor] = useState("");
  const [start_date, set_start_date] = useState("");
  const [start_time, set_start_time] = useState("");
  const [end_date, set_end_date] = useState("");
  const [end_time, set_end_time] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [duration, setDuration] = useState("");
  const [event_attendees_value, set_event_attendees_value] = useState([]);
  const [update_all, set_update_all] = useState(false);

  const [email_students, set_email_students] = useState(false);
  const [email_parents, set_email_parents] = useState(false);
  const [email_tutors, set_email_tutors] = useState(false);
  const [sms_students, set_sms_students] = useState(false);
  const [sms_parents, set_sms_parents] = useState(false);
  const [sms_tutors, set_sms_tutors] = useState(false);
  const [notes, set_notes] = useState("");
  const [delete_all, set_delete_all] = useState(false);
  const [attendees_info, set_attendees_info] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudentNames, setSelectedStudentNames] = useState([]);
  const [val, setVal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOpenErrors, setIsOpenErrors] = useState(false);
  const [groupStudentsData, setGroupStudentsData] = useState([]);
  const [othersStudents, setOthersStudents] = useState(false);
  const selectedCurrency = CurrencyService.getCurrency();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#363636" : "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDarkMode ? "#363636" : "white",
        color: isDarkMode ? "#FFF" : "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const resetForm = () => {
    set_event_attendees([]);
    set_event_attendees_value([]);
    set_location_id("");
    set_is_public("1");
    set_event_recurring(false);
    set_event_frequency("Daily");
    set_event_repeat_on(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
    set_event_frequency_val("1");
    set_event_repeat_indefinitely(true);
    set_event_repeat_until("");
    set_require_makeup_credits(false);
    set_event_public_desc("");
    set_pubdesc_on_calendar(false);
    set_event_private_desc("");
    set_allow_registration(false);
    set_registration_max(0);
    set_student_pricing("default");
    set_student_pricing_val(0);
    set_event_all_day(false);
    set_eventcat_id("");
    set_event_subtutor("");
    set_event_name("");
    set_event_tutor("");
    // set_start_date(formatDate(new Date()));
    set_start_time("");
    // set_end_date(formatDate(new Date()));
    set_end_time("");
    setDuration("");
  };

  const resetDeleteForm = () => {
    set_email_students(false);
    set_email_parents(false);
    set_email_tutors(false);
    set_sms_students(false);
    set_sms_parents(false);
    set_sms_tutors(false);
    set_notes("");
  };

  const getFrequency = (freq) => {
    if (freq == "Daily") {
      return 1;
    } else if (freq == "Weekly") {
      return 2;
    } else if (freq == "Monthly") {
      return 3;
    } else if (freq == "Yearly") {
      return 4;
    }
  };

  const getFrequencyString = (freq) => {
    if (freq == 1) {
      return "Daily";
    } else if (freq == 2) {
      return "Weekly";
    } else if (freq == 3) {
      return "Monthly";
    } else if (freq == 4) {
      return "Yearly";
    }
  };

  useEffect(() => {
    const fetchAllStudentData = async () => {
      const result = await getAllStudents();
      const allStudents = result?.data?.map((e) => {
        return { value: e.id, label: e.name };
      }) || [];
      setAllStudentsList(allStudents);
      setStudentsList(allStudents);
    }
    if (role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}`) {
      fetchAllStudentData()
    }
  }, [role]);

  const calculateEndDateTimeByDuration = (duration) => {
    let durationMs = parseInt(duration) * 60000;
    let hours = 0;
    let minutes = 0;
    if (start_time) {
      [hours, minutes] = start_time.split(":").map(Number);
    }
    let startDateTime = new Date(start_date);
    startDateTime.setHours(hours);
    startDateTime.setMinutes(minutes);
    let endDate = new Date(startDateTime.getTime() + durationMs); // Convert duration to milliseconds and add to start date
    let end_date_str = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
    set_end_date(end_date_str);
    set_end_time(`${endDate.getHours()}:${endDate.getMinutes()}`);
  };

  useEffect(() => {
    calculateEndDateTimeByDuration(duration);
  }, [duration,start_time]);

  const getCategoriesHandler = async () => {
    const result = await getCategories();
    setCategoriesList(result?.data);
  };

  const getEventDetailsByIdHandler = async (id) => {
    const occurrence_date = start_date;
    console.log("occurrence_date is from edit events------", occurrence_date);
    resetForm();
    const result = await getEventDetailsById(id, occurrence_date);

    let attendees_ids = [];
    try {
      if (result?.data?.event_attendees) {
        attendees_ids = JSON.parse(result.data.event_attendees);
      }
    } catch (error) {
      console.error("Error parsing event_attendees:", error);
    }

    await getStudentsByTutorIdHandler(result.data.event_tutor);

    const selectedAttendees = studentsList.filter((e) => {
      return attendees_ids.includes(e.value);
    });

    set_event_attendees_value(selectedAttendees);
    set_event_attendees(selectedAttendees);

    set_location_id(result.data.location_id);
    set_is_public(result.data.is_public);
    set_event_recurring(result.data.event_recurring);
    set_event_frequency(getFrequencyString(result.data.event_frequency));
    set_event_repeat_on(result.data.event_repeat_on ? JSON.parse(result.data.event_repeat_on) : []);
    set_event_frequency_val(result.data.event_frequency_val);
    set_event_repeat_indefinitely(result.data.event_repeat_indefinitely);
    set_event_repeat_until(result.data.event_repeat_until);
    set_require_makeup_credits(result.data.require_makeup_credits);
    set_event_public_desc(result.data.event_public_desc);
    set_pubdesc_on_calendar(result.data.pubdesc_on_calendar);
    set_event_private_desc(result.data.event_private_desc);
    set_allow_registration(result.data.allow_registration);
    set_registration_max(result.data.registration_max);
    set_student_pricing(result.data.student_pricing);
    set_student_pricing_val(result.data.student_pricing_val);
    set_event_all_day(result.data.event_all_day);
    set_eventcat_id(result.data.eventcat_id);
    set_event_subtutor(result.data.event_subtutor);
    set_event_name(result.data.event_name);
    set_event_tutor(result.data.event_tutor);
    set_start_date(result.data.start_date);
    set_start_time(result.data.start_time);
    set_end_date(formatDate(result.data.end_date));
    set_end_time(result.data.end_time);
    set_eventtype_id(result.data.eventtype_id);
    setDuration(result.data.event_duration);
    setShowSubstituteTutor(result.data.event_subtutor !== null);
    set_update_all(false);
    setIsEditForm(true);

    setOthersStudents(result.data.student_other_tutors);
    if (result.data.student_other_tutors) {
      setStudentsList(allStudentsList);
    }

    if (result.data.eventtype_id === 1) {
      openModal("quickAddLesson");
    } else if (result.data.eventtype_id === 2) {
      openModal("newEvent");
    } else if (result.data.eventtype_id === 3) {
      openModal("newNonTutoringEvent");
    }
  };


  const takeAttendanceHandler = async (id) => {
    // navigate("/calendar/attendance", { state: { id: id } });   // The id is passed as part of the state object to the route, so it's not visible in the URL.
    navigate("/calendar/attendance/" + id);
  };

  const createCloneHandler = async (id) => {
    resetForm();
    const result = await getEventDetailsById(id);
    set_start_date(formatDate(result.data.start_date));
    set_start_time(result.data.start_time);
    set_end_date(formatDate(result.data.end_date));
    set_end_time(result.data.end_time);
    set_event_name(result.data.event_name);
    openModal("cloneEvent");
  };

  const deleteEventsHandler = async (e, delete_all_status) => {
    e.preventDefault();
    const data = {
      email_students: email_students,
      email_parents: email_parents,
      email_tutors: email_tutors,
      sms_students: sms_students,
      sms_parents: sms_parents,
      sms_tutors: sms_tutors,
      notes: notes,
      delete_all: delete_all_status,
    };
    const response = await deleteEvents(data, selectedEventId);
    if (response.success) {
      toast.success(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      fetchEventsForVisibleRange(visibleRange);
      setIsOpen(false);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const navigate = useNavigate();

  const localizer = momentLocalizer(moment);

  useEffect(() => {
    if (allTutors.length > 0) {
      // Set the selectedTutor to the id of the first tutor
      setSelectedTutor(allTutors[0].id);
    }
  }, [allTutors]);

  useEffect(() => {
    if (role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}`) {
      fetchStudentData();
      fetchStudentGroupData();
      getTutor();
    }
    fetchEventsForVisibleRange(visibleRange);
  }, [role]);

  useEffect(() => {
    studentGroupData != false && setVal(true);
  }, [studentGroupData]);

  const groupStudents = val ? studentGroupData : [];

  // handle the checkbox change functionality for others student
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setOthersStudents(isChecked ? 1 : 0);
    if (isChecked) {
      setStudentsList(allStudentsList);
      fetchStudentGroupData();
    } else {
      getStudentsByTutorIdHandler(event_tutor);
      fetchStudentGroupData(event_tutor);
    }
  };


  const handleGroupChange = (id, students) => {
    // Toggle group selection
    setSelectedGroups(prev =>
      prev.includes(id)
        ? prev.filter(groupId => groupId !== id)
        : [...prev, id]
    );

    // Prepare the new students and attendees to add
    const newAttendeesList = students.filter(
      student => !event_attendees.some(existingStudent => existingStudent.value === student.id)
    ).map(student => ({
      value: student.id,
      label: student.name
    }));

    // Update eventAttendees
    set_event_attendees(prevEventAttendees => {
      // If group is being deselected, remove the group's students from attendees
      if (selectedGroups.includes(id)) {
        return prevEventAttendees.filter(
          attendee => !students.some(student => student.id === attendee.value)
        );
      } else {
        // If group is being selected, add the group's students to attendees
        return [
          ...prevEventAttendees,
          ...newAttendeesList.filter(
            student => !prevEventAttendees.some(existingStudent => existingStudent.value === student.value)
          )
        ];
      }
    });
  };

  // Handler to remove a selected student
  const handleRemoveStudent = (id) => {
    const updatedSelectedStudents = selectedStudents.filter(student => student !== id);
    const updatedSelectedTextStudents = selectedStudentNames.filter(student => student.id !== id);
    setSelectedStudents(updatedSelectedStudents);
    setSelectedStudentNames(updatedSelectedTextStudents);
  };

  const getStudentsByTutorIdHandler = async (id) => {
    set_event_tutor(id);
    set_event_attendees([]);
    setOthersStudents(0);
    if (id === "Select Tutor" || id === "" || id == null) {
      // Fetch all students if no tutor is selected
      setStudentsList(allStudentsList);
      fetchStudentGroupData();
    } else {
      // Fetch students by tutor ID
      const result = await getStudentsByTutorId(id);
      const studentsByTutorId =
        result?.data?.map((e) => {
          return { value: e.student.id, label: e.student.name };
        }) || [];
      setStudentsList(studentsByTutorId);
      fetchStudentGroupData(id);
    }
  };

  // Fetch events for the given date range
  const fetchEventsForVisibleRange = async (range) => {
    const startDate = moment(range.start).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(range.end).endOf("month").format("YYYY-MM-DD");
    fetchEvent(startDate, endDate);
  };

  const handleRangeChange = (range) => {
    setVisibleRange(range);
    fetchEventsForVisibleRange(range);
  };

  let eventArr = [];
  if (allEvents) {
    allEvents?.forEach((element) => {
      let myObject = {
        id: element?.id,
        attendees_info: element?.attendees_info,
        title: element?.event_name,
        start: new Date(element?.start_date),
        end: new Date(element?.end_date),
        start_time: element?.start_time,
        end_time: element?.end_time,
        desc: element?.event_public_desc || "",
        private_desc: element?.event_private_desc || "",
        event_duration: element?.event_duration || "",
        event_tutor: element?.event_tutor || "",
        tutor_name: element?.tutor_name || "",
        take_attendance: element?.take_attendance || "",
        attendance_pending: element?.attendance_pending || false,
      };

      eventArr.push(myObject);
    });
  }

  function openModal(e) {
    setIsOpen(e);
    setErrors({}); // Clear errors when opening the modal
    setIsOpenErrors(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal(e) {
    setIsOpen(e);
    setErrors({}); // Clear errors when closing the modal
    setIsOpenErrors(false);
  }

  const handleNewEventChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "event_repeats") {
      if (e.target.checked) {
        setEventRepeats(true);
      } else {
        setEventRepeats(false);
      }
    }
    if (name === "start_date") {
      // Use the selected date if it's different from the default eventDate
      const selectedDate = formatDate(startDate);

      setStartDate(value !== selectedDate ? value : formatDate(startDate));
    }

    if (name === "repeats_indefinitely") {
      if (e.target.checked) {
        setRepeatsIndefinitely(true);
      } else {
        setRepeatsIndefinitely(false);
      }
    }
    if (name === "frequency") {
      setSelectedOption(e.target.value);
    }

    if (name === "time") {
      addEvent["start_time"] = e.target.value;
    }

    if (name === "all_day") {
      setAllDay((prev) => !prev);
      setDisableTimeDuration((prev) => !prev); // Disable date and time fields when "All Day" is checked
      let end_time = "23:59"; // Default end time for "All Day" events
      addEvent["end_time"] = end_time;
      addEvent["end_date"] = formatDate(startDate);
    }
    if (name === "add_substitute_tutor") {
      setShowSubstituteTutor((prev) => !prev);
    }

    if (name === "tutor") {
      setSelectedTutor(e.target.value);
      // Filter out the selected tutor from the list of all tutors
    }

    if (name === "every" && eventRepeats === true) {
      const parsedValue = parseInt(value); // Ensure the value is parsed as an integer
      setEveryWeeks(parsedValue); // Update everyWeeks state
      setAddEvent((prev) => ({ ...prev, [name]: parsedValue })); // Update addEvent state
    } else {
      setAddEvent({ ...addEvent, [name]: value });
    }
  };

  const filteredTutors = allTutors.filter(
    (tutor) => tutor.id !== parseInt(selectedTutor)
  );

  const saveEvent = async (e, update_all_status) => {
    e.preventDefault();

    console.log("Occurrence Date:", start_date);
    const formData = {
      event_all_day: event_all_day ? 1 : 0,
      eventtype_id: eventtype_id,
      eventcat_id: eventcat_id,
      event_subtutor: event_subtutor,
      event_name: event_name,
      event_tutor: event_tutor,
      start_date: start_date,
      start_time: start_time,
      end_date: end_date,
      end_time: end_time,
      event_attendees: JSON.stringify(
        event_attendees.map((e) => e.value) || []
      ),
      location_id: location_id,
      is_public: is_public,
      event_recurring: event_recurring ? 1 : 0,
      event_frequency: getFrequency(event_frequency),
      event_repeat_on: JSON.stringify(
        event_repeat_on
          ?.filter((f) => f.isActive == true)
          ?.map((e) => e.label) || []
      ),
      event_frequency_val: event_frequency_val,
      event_repeat_indefinitely: event_repeat_indefinitely ? 1 : 0,
      event_repeat_until: event_repeat_until,
      require_makeup_credits: require_makeup_credits,
      event_public_desc: event_public_desc,
      pubdesc_on_calendar: pubdesc_on_calendar ? 1 : 0,
      event_private_desc: event_private_desc,
      allow_registration: allow_registration ? 1 : 0,
      registration_max: registration_max,
      student_pricing: student_pricing,
      student_pricing_val: student_pricing_val,
      update_all: update_all_status,
      student_other_tutors: othersStudents,
    };
    setErrors({})
    // return ;
    let response
    if (isEditForm) {
      // Edit Form
      response = await updateEvents(formData, selectedEventId, start_date);
      if (response.success) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchEventsForVisibleRange(visibleRange);
        setIsOpen(false);
      } else {
        setErrors(response?.response.data.data || {});
      }
    } else {
      // Add Form
      response = await createEvents(formData);
      if (response.success) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchEventsForVisibleRange(visibleRange);
        setIsOpen(false);
      } else {
        setErrors(response?.response.data.data || {});
      }
    }
  };

  const saveAllEvents = async (e) => {
    saveEvent(e, true);
  };

  const saveOneEvents = async (e) => {
    saveEvent(e, false);
  };

  const cloneEventsHandler = async (e) => {
    e.preventDefault();
    const occurrenceDate = start_date;  // or combine with start_time if needed
    console.log("Occurrence Date from clone events----------", occurrenceDate);

    const data = {
      start_date: start_date,
      start_time: start_time,
      end_date: end_date,
      end_time: end_time,
      id: selectedEventId,
    };
    const response = await cloneEvents(data, start_date );
    if (response.success) {
      toast.success(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      fetchEventsForVisibleRange(visibleRange);
      setIsOpen(false);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleSelectedEvent = (e) => {
    setSelectedEventId(e.id);
    openModal("event");
    set_attendees_info(e.attendees_info);
    let [hours, minutes] = e.start_time.split(":");
    let newTimeString = `${hours}:${minutes}`;
    let [endhours, endminutes] = e.end_time.split(":");
    let endTimeString = `${endhours}:${endminutes}`;
    // Convert start and end dates to string format
    const startDateString = formatDate(e.start);
    const endDateString = e.end.toLocaleString();
    set_start_date(formatDate(e.start));
    set_end_date(formatDate(e.end));
    setEventStartTime(newTimeString);
    setEventEndTime(endTimeString);
    setEventDuration(e.event_duration);
    setTutorName(e.tutor_name);
    setTutorId(e.event_tutor);
    setEventTitle(e.title);
    setTakeAttendance(e.take_attendance);
    setEventDesc({ ...e, start: startDateString, end: endDateString });
  };

  const handleStudentEventAction = (e) => {
    setSelectedEventId(e.id);
    setEventData(e);
    openModal("studentEvent");
    set_attendees_info(e.attendees_info);
    let [hours, minutes] = e.start_time.split(":");
    let newTimeString = `${hours}:${minutes}`;
    let [endhours, endminutes] = e.end_time.split(":");
    let endTimeString = `${endhours}:${endminutes}`;
    const startDateString = formatDate(e.start);
    const endDateString = e.end.toLocaleString();
    set_start_date(formatDate(e.start));
    set_end_date(formatDate(e.end));
    setEventStartTime(newTimeString);
    setEventEndTime(endTimeString);
    setEventDuration(e.event_duration);
    setTutorName(e.tutor_name);
    setTutorId(e.event_tutor);
    setEventTitle(e.title);
    setTakeAttendance(e.take_attendance);
    setEventDesc({ ...e, start: startDateString, end: endDateString });
  }

  const openNewAppointmentModal = (e) => {
    // Convert start and end dates to string format
    set_start_date(formatDate(e.start));
    set_end_date(formatDate(e.start));
    openModal("addEvent");
  };
  const openQuickAddLessonModal = (e) => {
    resetForm();
    set_eventtype_id(1);
    setIsEditForm(false);
    openModal("quickAddLesson");
  };
  useEffect(() => {
    if (role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}`) {
      fetchLocation();
      getCategoriesHandler();
    }
  }, [userId, role]);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const openNewEventModal = (e) => {
    resetForm();
    set_eventtype_id(2);
    setIsEditForm(false);
    openModal("newEvent");
  };
  const openNewNonTutoringEventModal = (e) => {
    resetForm();
    set_eventtype_id(3);
    setIsEditForm(false);
    openModal("newNonTutoringEvent");
  };

  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />
        <EventModal
          bodyOpenClassName="react-modal"
          isOpen={modalIsOpen === "event"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          getEventDetailsByIdHandler={getEventDetailsByIdHandler}
          selectedEventId={selectedEventId}
          createCloneHandler={createCloneHandler}
          resetDeleteForm={resetDeleteForm}
          openModal={openModal}
          userData={userData}
          eventDuration={eventDuration}
          eventTitle={eventTitle}
          eventDesc={eventDesc}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          attendees_info={attendees_info}
          takeAttendanceHandler={takeAttendanceHandler}
          tutorName={tutorName}
          tutorId={tutorId}
          takeAttendance={takeAttendance}
        />

        <StudentEventModal
          bodyOpenClassName="react-modal"
          isOpen={modalIsOpen === "studentEvent"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Event Details"
          closeModal={closeModal}
          selectedEventId={selectedEventId}
          eventDuration={eventDuration}
          eventTitle={eventTitle}
          eventDesc={eventDesc}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          attendees_info={attendees_info}
          takeAttendanceHandler={takeAttendanceHandler}
          tutorName={tutorName}
          tutorId={tutorId}
          takeAttendance={takeAttendance}
          eventData={eventData}
        />

        <AddEventModal
          isOpen={modalIsOpen === "addEvent"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={addEventStyles}
          contentLabel="Example Modal"
          eventDate={eventDate}
          closeModal={closeModal}
          openQuickAddLessonModal={openQuickAddLessonModal}
          openNewEventModal={openNewEventModal}
          openNewNonTutoringEventModal={openNewNonTutoringEventModal}
        />

        <QuickAddLessonModal
          isOpen={modalIsOpen === "quickAddLesson"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={quickAddLessonStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          isEditForm={isEditForm}
          event_name={event_name}
          set_event_name={set_event_name}
          errors={errors}
          event_tutor={event_tutor}
          getStudentsByTutorIdHandler={getStudentsByTutorIdHandler}
          allTutors={allTutors}
          event_attendees_value={event_attendees_value}
          set_event_attendees={set_event_attendees}
          studentsList={studentsList}
          colourStyles={colourStyles}
          location_id={location_id}
          set_location_id={set_location_id}
          allLocation={allLocation}
          start_date={start_date}
          formatDate={formatDate}
          set_start_date={set_start_date}
          end_date={end_date}
          set_end_date={set_end_date}
          start_time={start_time}
          set_start_time={set_start_time}
          end_time={end_time}
          set_end_time={set_end_time}
          event_recurring={event_recurring}
          set_event_recurring={set_event_recurring}
          event_frequency={event_frequency}
          set_event_frequency={set_event_frequency}
          event_repeat_on={event_repeat_on}
          set_event_repeat_on={set_event_repeat_on}
          event_frequency_val={event_frequency_val}
          set_event_frequency_val={set_event_frequency_val}
          event_repeat_until={event_repeat_until}
          set_event_repeat_until={set_event_repeat_until}
          event_repeat_indefinitely={event_repeat_indefinitely}
          set_event_repeat_indefinitely={set_event_repeat_indefinitely}
          is_public={is_public}
          set_is_public={set_is_public}
          require_makeup_credits={require_makeup_credits}
          set_require_makeup_credits={set_require_makeup_credits}
          saveAllEvents={saveAllEvents}
          saveOneEvents={saveOneEvents}
        />

        <NewEventModal
          isOpen={modalIsOpen === "newEvent"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={quickAddLessonStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          isEditForm={isEditForm}
          event_name={event_name}
          set_event_name={set_event_name}
          event_tutor={event_tutor}
          getStudentsByTutorIdHandler={getStudentsByTutorIdHandler}
          allTutors={allTutors}
          showSubstituteTutor={showSubstituteTutor}
          setShowSubstituteTutor={setShowSubstituteTutor}
          event_subtutor={event_subtutor}
          set_event_subtutor={set_event_subtutor}
          filteredTutors={filteredTutors}
          is_public={is_public}
          set_is_public={set_is_public}
          require_makeup_credits={require_makeup_credits}
          set_require_makeup_credits={set_require_makeup_credits}
          allow_registration={allow_registration}
          set_allow_registration={set_allow_registration}
          othersStudents={othersStudents}
          handleCheckboxChange={handleCheckboxChange}
          event_attendees={event_attendees}
          set_event_attendees={set_event_attendees}
          event_attendees_value={event_attendees_value}
          studentsList={studentsList}
          colourStyles={colourStyles}
          groupStudents={groupStudents}
          handleGroupChange={handleGroupChange}
          selectedGroups={selectedGroups}
          eventcat_id={eventcat_id}
          set_eventcat_id={set_eventcat_id}
          categoriesList={categoriesList}
          location_id={location_id}
          set_location_id={set_location_id}
          allLocation={allLocation}
          start_date={start_date}
          formatDate={formatDate}
          set_start_date={set_start_date}
          start_time={start_time}
          set_start_time={set_start_time}
          duration={duration}
          setDuration={setDuration}
          calculateEndDateTimeByDuration={calculateEndDateTimeByDuration}
          event_all_day={event_all_day}
          set_event_all_day={set_event_all_day}
          event_recurring={event_recurring}
          set_event_recurring={set_event_recurring}
          event_frequency={event_frequency}
          set_event_frequency={set_event_frequency}
          event_repeat_on={event_repeat_on}
          set_event_repeat_on={set_event_repeat_on}
          event_frequency_val={event_frequency_val}
          set_event_frequency_val={set_event_frequency_val}
          event_repeat_until={event_repeat_until}
          set_event_repeat_until={set_event_repeat_until}
          event_repeat_indefinitely={event_repeat_indefinitely}
          set_event_repeat_indefinitely={set_event_repeat_indefinitely}
          student_pricing={student_pricing}
          set_student_pricing={set_student_pricing}
          student_pricing_val={student_pricing_val}
          set_student_pricing_val={set_student_pricing_val}
          event_public_desc={event_public_desc}
          set_event_public_desc={set_event_public_desc}
          event_private_desc={event_private_desc}
          set_event_private_desc={set_event_private_desc}
          errors={errors}
          saveOneEvents={saveOneEvents}
          saveAllEvents={saveAllEvents}
          selectedCurrency={selectedCurrency}
        />

        <NewNonTutoringEventModal
          isOpen={modalIsOpen === "newNonTutoringEvent"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={quickAddLessonStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          isEditForm={isEditForm}
          event_name={event_name}
          set_event_name={set_event_name}
          event_tutor={event_tutor}
          set_event_tutor={set_event_tutor}
          allTutors={allTutors}
          eventcat_id={eventcat_id}
          set_eventcat_id={set_eventcat_id}
          categoriesList={categoriesList}
          // eventcat_name,
          location_id={location_id}
          set_location_id={set_location_id}
          allLocation={allLocation}
          start_date={start_date}
          formatDate={formatDate}
          handleNewEventChange={handleNewEventChange}
          set_start_date={set_start_date}
          start_time={start_time}
          set_start_time={set_start_time}
          duration={duration}
          setDuration={setDuration}
          calculateEndDateTimeByDuration={calculateEndDateTimeByDuration}
          disableTimeDuration={disableTimeDuration}
          errors={errors}
          event_all_day={event_all_day}
          set_event_all_day={set_event_all_day}
          event_recurring={event_recurring}
          set_event_recurring={set_event_recurring}
          event_frequency={event_frequency}
          set_event_frequency={set_event_frequency}
          event_repeat_on={event_repeat_on}
          set_event_repeat_on={set_event_repeat_on}
          event_frequency_val={event_frequency_val}
          set_event_frequency_val={set_event_frequency_val}
          event_repeat_until={event_repeat_until}
          set_event_repeat_until={set_event_repeat_until}
          event_repeat_indefinitely={event_repeat_indefinitely}
          set_event_repeat_indefinitely={set_event_repeat_indefinitely}
          event_public_desc={event_public_desc}
          set_event_public_desc={set_event_public_desc}
          pubdesc_on_calendar={pubdesc_on_calendar}
          set_pubdesc_on_calendar={set_pubdesc_on_calendar}
          saveAllEvents={saveAllEvents}
          saveOneEvents={saveOneEvents}
        />

        <DeleteEventModal
          isOpen={modalIsOpen === "deleteEvent"}
          afterOpenModal={afterOpenModal}
          onRequestClose={closeModal}
          style={quickAddLessonStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          email_students={email_students}
          set_email_students={set_email_students}
          email_parents={email_parents}
          set_email_parents={set_email_parents}
          email_tutors={email_tutors}
          set_email_tutors={set_email_tutors}
          sms_students={sms_students}
          set_sms_students={set_sms_students}
          sms_parents={sms_parents}
          set_sms_parents={set_sms_parents}
          sms_tutors={sms_tutors}
          set_sms_tutors={set_sms_tutors}
          notes={notes}
          set_notes={set_notes}
          deleteEventsHandler={deleteEventsHandler}
        />

        <CloneEventModal
          isOpen={modalIsOpen === "cloneEvent"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={quickAddLessonStyles}
          contentLabel="Example Modal"
          closeModal={closeModal}
          event_name={event_name}
          start_date={start_date}
          end_date={end_date}
          start_time={start_time}
          end_time={end_time}
          set_start_date={set_start_date}
          set_end_date={set_end_date}
          set_start_time={set_start_time}
          set_end_time={set_end_time}
          cloneEventsHandler={set_end_time}
          formatDate={formatDate}
        />

        <main className="content">
          <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-12 col-xxl-12">
                <div style={{ zIndex: "1" }}>

                  {role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}` &&
                    <div className="card flex-fill w-100">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-4">
                            <OptionBox
                              icon={settings}
                              options={[
                                {
                                  label: "Categories & Locations",
                                  route: "/calendar/categories",
                                },
                              ]}
                              label={"Options"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {/* rushita */}
                  <Calendar
                    localizer={localizer}
                    events={eventArr}
                    style={{ height: 500 }}
                    step={60}
                    popup={true}
                    selectable={true}
                    // onSelectSlot={(e) => openNewAppointmentModal(e)}
                    // onSelectEvent={(e) => handleSelectedEvent(e)}
                    onSelectSlot={(e) => {
                      if (role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}`) {
                        openNewAppointmentModal(e);
                      }
                    }}
                    onSelectEvent={(e) => {
                      if (role === `${process.env.REACT_APP_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_SINGLE_BUSINESS_ROLE}` || role === `${process.env.REACT_APP_TUTOR_ROLE}`) {
                        handleSelectedEvent(e);
                      } else if (role === `${process.env.REACT_APP_STUDENT_ROLE}` || role === `${process.env.REACT_APP_PARENT_ROLE}`) {
                        handleStudentEventAction(e);
                      }
                    }}
                    onRangeChange={handleRangeChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Calendars;
