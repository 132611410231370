import React, { useRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { useUserDataContext } from "../../../contextApi/userDataContext"; // Update the path if necessary
import moment from "moment";
import "../../../components/users/assets/css/style.css";
import { Tooltip, OverlayTrigger } from 'react-bootstrap'; // Import Bootstrap components
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from "@mui/material";
import ChecklistRtl from '@mui/icons-material/ChecklistRtl';
import AddIcCall from '@mui/icons-material/AddIcCall';


const UpcomingEvents = ({type}) => {
  const { fetchEvent,
    allEvents,
    getTutor,
    allTutors } = useUserDataContext();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTutor, setSelectedTutor] = useState("all"); // Default to all tutors
  const datePickerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch upcoming events when the component mounts
    fetchEvent(moment(selectedDate).format("YYYY-MM-DD"), moment(selectedDate).format("YYYY-MM-DD"),selectedTutor);
    getTutor();
  }, []);

  useEffect(() => {
    // Set the events state when upcomingEvents updates
    if (allEvents) {
      setEvents(allEvents);
    }
  }, [allEvents]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    fetchEvent(moment(date).format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"), selectedTutor);
  };

  const handleClick = () => {
    datePickerRef.current.setFocus();
  };

  const handleTutorChange = (e) => {
    const tutorId = e.target.value;
    setSelectedTutor(tutorId);
    // Fetch events for the selected date and tutor
    fetchEvent(moment(selectedDate).format("YYYY-MM-DD"), moment(selectedDate).format("YYYY-MM-DD"), tutorId);
  };

  const takeAttendanceHandler = async (id) => {
    navigate("/calendar/attendance/" + id);
  };

  const joinEvent = async (eventId) => {
    console.log("join class : ", eventId);
  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-input-wrapper" >
      <div className="custom-input" onClick={onClick} ref={ref} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
        <CalendarMonthIcon style={{ marginLeft: '8px', fontSize: '1.75em', viewBox: "0 0 48 48"}} />
        <input
          type="text"
          value={value}
          readOnly
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.5em"
          height="1.5em"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-chevron-down"
          style={{ marginLeft: '8px' }}
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </div>
    </div>
  ));

  return (
    <div className="upcoming-events-container">
      <div className="dashboard-events">

        <DatePicker
          ref={datePickerRef}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="dd-MM-yyyy"
          onClickOutside={() => datePickerRef.current.setOpen(false)} // Close on clicking outside
          customInput={<CustomInput />} // Custom input with SVG and arrow
          popperPlacement="bottom-start" 
        />
        <div className="event-agenda">
          {
            (type == 'tutor') &&
              <h1 className="h3 mb-0">
                <strong>Agenda</strong>
              </h1>
          }
          { (type == 'business') &&
            <>
              <h1 className="h3 mb-0">
                <strong>Agenda for</strong>
              </h1>
              <select value={selectedTutor} onChange={handleTutorChange} class="form-select-lg p-2">
                <option value="all">All Tutor</option>
                {allTutors.map((tutor) => (
                  <option key={tutor.id} value={tutor.id}>
                  {tutor.name}
                  </option>
                ))}
              </select>
            </>
          }
          {
            (type == 'student') &&
              <h1 className="h3 mb-0">
                <strong>Your Schedule</strong>
              </h1>
          }
        </div>
      </div>
      <hr class="hr" />
      {events.length > 0 ? (
        <ul className="list-group">
          {events.map((event) => {
            const attendees = event.attendees_info;
        
            const renderTooltip = (props) => (
              <Tooltip id="attendees-tooltip" {...props}>
                {attendees.length > 0 ? (
                  <ul className="list-unstyled mb-0">
                    {attendees.map((attendee, index) => (
                      <li key={index}>
                        {attendee.name} {attendee.attendance_taken && attendee.attendance_status == '1' ? '(U)' : 
                                          attendee.attendance_status == '2' ? '(P)' :
                                          attendee.attendance_status == '3' ? '(A)' :
                                          attendee.attendance_status == '4' ? '(TC)': ''
                                        }
                      </li>
                    ))}
                  </ul>
                ) : (
                  "No attendees"
                )}
              </Tooltip>
            );
        
            return (
              <li key={event.event_id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <strong>{event.event_name}</strong>
                  <br />
                  <small className="text-muted">
                    {event.start_date} [ {event.start_time} - {event.end_time} ]
                  </small>
                </div>
                {
                  (type != 'student') ?
                  <>
                    <div className="tips-width">
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip}
                      >
                        <span className="badge bg-success rounded-pill">
                          {attendees.length} Attendees
                        </span>
                      </OverlayTrigger>
                      {event.attendance_pending && (
                        <span className="badge bg-danger rounded-pill">
                          Attendance Pending
                        </span>
                      )}
                    </div>
                    <Button 
                            variant="contained" 
                            size="small"
                            color="primary"
                            onClick={() => takeAttendanceHandler(event.id)}
                            startIcon={<ChecklistRtl color="secondary" />} 
                            disabled={!event.take_attendance}
                      >
                      Take Attendance
                    </Button>
                  </>
                  :
                  <>
                        <span className="badge bg-info rounded-pill">
                          {event.event_cat.eventcat_name}
                        </span>
                        <Button 
                              variant="contained" 
                              size="small"
                              color="primary"
                              onClick={() => joinEvent(event.id)}
                              startIcon={<AddIcCall color="secondary" />} 
                              disabled={!event.take_attendance}
                        >
                        Join Now
                      </Button>
                  </>
                }
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="alert alert-info" role="alert">
          No upcoming events
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
