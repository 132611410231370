import React from "react";
import { useTranslation } from "react-i18next";

const MileageSettings = ({ handleChange, formData, formSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingMileage">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseMileage"
              aria-expanded="false"
              aria-controls="flush-collapseMileage"
            >
              <strong>{t("Mileage Expenses")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseMileage"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingMileage"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <p>{t("Set up automatic expense calculations for mileage entries")}</p>
              <div className="form-group">
                <div className="preference">
                  <input
                    type="checkbox"
                    name="auto_create_expense_entries"
                    onChange={handleChange}
                    checked={formData.auto_create_expense_entries}
                  />{" "}
                  {t("Automatically create expense entries")}
                </div>
              </div>
              <div className="formbold-form-btn-wrapper justify-content-end">
                <button className="btn btn-secondary" onClick={formSubmit}>
                  {t("Save Changes")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MileageSettings;
