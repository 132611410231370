import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const StudentPortalSettings = ({ handleChange, formData, formSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingStudentPortal">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseStudentPortal"
              aria-expanded="false"
              aria-controls="flush-collapseStudentPortal"
            >
              <strong>{t("Student Portal Settings")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseStudentPortal"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingStudentPortal"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">

              {/* Permissions */}
              <div>
                <div className="student-properties-edit sec-acc justify-content-between p-1 mb-0">
                  <h3>{t("Permissions")}</h3>
                </div>
                <p className="subtitle mb-3">Set which areas students/parents can edit themselves</p>
                
                <div className="ps-3">
                  <div className="preference mb-3">
                    <input
                      type="checkbox"
                      name="allow_edit_contact_info"
                      onChange={handleChange}
                      checked={formData.allow_edit_contact_info}
                    />{" "}
                    {t("Allow parents/students to edit their contact info")}
                  </div>

                  <div className="preference mb-0">
                    <input
                      type="checkbox"
                      name="allow_different_timezone"
                      onChange={handleChange}
                      checked={formData.allow_different_timezone}
                    />{" "}
                    {t("Allow students to use a different time zone than yours")}
                  </div>
                  <p className="subtitle ps-3 mb-3">Use if you have remote students</p>


                  <div className="preference mb-0">
                    <input
                      type="checkbox"
                      name="allow_partial_online_payments"
                      onChange={handleChange}
                      checked={formData.allow_partial_online_payments}
                    />{" "}
                    {t("Allow partial online payments")}
                  </div>
                  <p className="subtitle ps-3 mb-3">Use to allow students/parents to adjust the amount when making a payment</p>
                </div>
                <br></br>
              </div>

              <div>
                <div className="student-properties-edit sec-acc justify-content-between p-1 mb-0">
                  <h3>{t("Visibility")}</h3>
                </div>
                <p className="subtitle mb-3">Choose which information students/parents can see</p>

                <div className="ps-3">
                  <div className="preference mb-0">
                    <input
                      type="checkbox"
                      name="show_all_attendees"
                      onChange={handleChange}
                      checked={formData.show_all_attendees}
                    />{" "}
                    {t("Show all attendees in calendar events")}
                  </div>
                  <p className="subtitle ps-3 mb-3">Student names will be visible to all attendees</p>


                  <div className="preference mb-3">
                    <input
                      type="checkbox"
                      name="show_tutor_contact"
                      onChange={handleChange}
                      checked={formData.show_tutor_contact}
                    />{" "}
                    {t("Show tutor email and phone number")}
                  </div>

                  <div className="preference mb-3">
                    <input
                      type="checkbox"
                      name="show_tutor_address"
                      onChange={handleChange}
                      checked={formData.show_tutor_address}
                    />{" "}
                    {t("Show tutor address")}
                  </div>

                  <div className="preference mb-3">
                    <input
                      type="checkbox"
                      name="show_make_up_credits"
                      onChange={handleChange}
                      checked={formData.show_make_up_credits}
                    />{" "}
                    {t("Show available make-up credits")}
                  </div>

                  <div className="preference mb-0">
                    <input
                      type="checkbox"
                      name="show_student_contact_list"
                      onChange={handleChange}
                      checked={formData.show_student_contact_list}
                    />{" "}
                    {t("Show student contact list")}
                  </div>
                  <p className="subtitle ps-3 mb-3">Student/parent phone numbers & email addresses will be visible to everyone in the Student Portal</p>

                  <div className="preference mb-0">
                    <input
                      type="checkbox"
                      name="show_account_invoices"
                      onChange={handleChange}
                      checked={formData.show_account_invoices}
                    />{" "}
                    {t("Show account & invoices to parents")}
                  </div>
                  <p className="subtitle ps-3 mb-3">Students never see account & invoices</p>
                </div>
                <br></br>
              </div>

              {/* Customization */}
              <div>
                <div className="student-properties-edit sec-acc justify-content-between p-1 mb-0">
                  <h3>{t("Customization")}</h3>
                </div>
                <p className="subtitle mb-3">Set which areas students/parents can edit themselves</p>
                  <div className="ps-3">
                    <div className="form-group">
                      <label className="formbold-form-label">{t("Initial Page")}</label>
                      <div className="d-flex ps-2">
                        <div className="me-3">
                          <input
                            type="radio"
                            id="initialPageHome"
                            name="initial_page"
                            value="home"
                            onChange={handleChange}
                            checked={formData.initial_page === "home"}
                          />{" "}
                          {t("Home")}
                        </div>
                        
                        <div className="me-3">
                          <input
                            type="radio"
                            id="initialPageCalendar"
                            name="initial_page"
                            value="calendar"
                            onChange={handleChange}
                            checked={formData.initial_page === "calendar"}
                          />{" "}
                          {t("Calendar")}
                        </div>

                        <div className="me-3">
                          <input
                            type="radio"
                            id="initialPageNews"
                            name="initial_page"
                            value="news"
                            onChange={handleChange}
                            checked={formData.initial_page === "news"}
                          />{" "}
                          {t("News")}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="formbold-form-label">
                        {t("Calendar Default View")}
                      </label>
                      <div className="d-flex ps-2">

                        <div className="me-3">
                          <input
                            type="radio"
                            id="calendarViewMonth"
                            name="calendar_default_view"
                            value="month"
                            onChange={handleChange}
                            checked={formData.calendar_default_view === "month"}
                          />{" "}
                          {t("Month")}
                        </div>

                        <div className="me-3">
                          <input
                            type="radio"
                            id="calendarViewWeek"
                            name="calendar_default_view"
                            value="week"
                            onChange={handleChange}
                            checked={formData.calendar_default_view === "week"}
                          />{" "}
                          {t("Week")}
                        </div>

                        <div className="me-3">
                          <input
                            type="radio"
                            id="calendarViewDay"
                            name="calendar_default_view"
                            value="day"
                            onChange={handleChange}
                            checked={formData.calendar_default_view === "day"}
                          />{" "}
                          {t("Day")}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="formbold-form-label">
                        {t("Calendar Color Code")}
                      </label>
                      <div className="d-flex ps-2">
                        <div className="me-3">
                          <input
                            type="radio"
                            id="colorCodeCategory"
                            name="calendar_color_code"
                            value="category"
                            onChange={handleChange}
                            checked={formData.calendar_color_code === "category"}
                          />{" "}
                          {t("By Category")}
                        </div>
                        <div className="me-3">
                          <input
                            type="radio"
                            id="colorCodeLocation"
                            name="calendar_color_code"
                            value="location"
                            onChange={handleChange}
                            checked={formData.calendar_color_code === "location"}
                          />{" "}
                          {t("By Location")}
                        </div>
                        <div className="me-3">
                          <input
                            type="radio"
                            id="colorCodeTutor"
                            name="calendar_color_code"
                            value="tutor"
                            onChange={handleChange}
                            checked={formData.calendar_color_code === "tutor"}
                          />{" "}
                          {t("By Tutor")}
                        </div>
                      </div>
                    </div>

                    <div className="formbold-form-btn-wrapper justify-content-end">
                      <div className="btn-end">
                        <button className="formbold-btn" onClick={formSubmit}>
                          {t("Save Changes")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPortalSettings;
