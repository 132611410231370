import React, { createContext, useContext, useEffect, useState } from "react";
import { SettingsContext } from "./SettingsContext";
import useGlobalSettings from "../hooks/useGlobalSettings.js";
import axios from "axios";
import { API_URL } from "../utils/config.js";
import { useNavigate } from "react-router-dom";
import { getDomainName } from "../services/loginService.js";

const userDataContext = createContext();

export const useUserDataContext = () => useContext(userDataContext);

const AppContext = ({ children }) => {
  const { getSetting } = useGlobalSettings();

  
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState("");
  const [centralPortalDomain, setCentralPortalDomain] = useState("");
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [emailTemplateData, setEmailTemplateData] = useState([]);
  const [emailOnchange, setEmailOnchange] = useState(false);
  const [studentData, setStudentData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [studentGroupData, setStudentGroupData] = useState(false);
  const [tutorData, setTutorData] = useState(false);
  const [allChargeCategory, setAllChargeCategory] = useState([]);
  const [allFamilies, setAllFamilies] = useState([]);
  const [accountInvoices, setAccountInvoices] = useState([]);
  const [allInvoicesByDate, setAllInvoicesByDate] = useState([]);
  const [allTimeZones, setAllTimeZones] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCurrencyFormats, setAllCurrencyFormats] = useState([]);
  const [allTutors, setAllTutors] = useState([]);
  const [getAvailabilityData, setGetAvailabilityData] = useState([]);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [studentAttendanceSummery, setStudentAttendanceSummery] = useState([]);
  const [studentFamilyContact, setStudentFamilyContact] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [emailData, setEmailData] = useState({
    template_title: "",
    template_content: "",
  });
  const [allTransactionsByFamily, setAllTransactionsByFamily] = useState([]);
  const [allTransactionsByDates, setAllTransactionsByDates] = useState([]);
  const storedMode = localStorage.getItem("isDarkMode");
  const initialMode = storedMode ? JSON.parse(storedMode) : false;
  const [isDarkMode, setIsDarkMode] = useState(initialMode);
  const [token, setToken] = useState(getSetting("authToken"));


  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      // Store mode in localStorage
      localStorage.setItem("isDarkMode", JSON.stringify(newMode));
      return newMode;
    });
  };

  const theme = isDarkMode ? "dark" : "light";

  useEffect(() => {
    document?.documentElement?.setAttribute("data-theme", theme);
  }, [isDarkMode]);

  
  // manageSidebar navbar navbar-expand
  useEffect(() => {
    if(sidebarToggle){
      const element = document.querySelector('.wrapperBody');
      if (element) {
        element.classList.add('manageSidebar');
      }
  
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        navbar.classList.add('manageNavbar');
      }
    }else{
      const element = document.querySelector('.wrapperBody');
      if (element) {
        element.classList.remove('manageSidebar');
      }

      const navbar = document.querySelector('.navbar');
      if (navbar) {
        navbar.classList.remove('manageNavbar');
      }
    }
    
  }, [sidebarToggle]);

  const navigate = useNavigate();

  const fetchData = async () => {
    console.log('call fetch data');
  };

  // getDashboardData
  const getDashboardData = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}data`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //getPrivileges
  const getPrivileges = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}all-permissions`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setPrivileges(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //getStudentStatus
  const getStudentStatus = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}student-statuses`,
    };
    await axios(validateconfig)
      .then((response) => {
        if (response.data.success === true) {
          setStatusList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logOut = () => {
    sessionStorage.clear();
    setTimeout(() => {
      navigate("/signin");
    }, 2000);
  };

  // Email Template start
  const emailTemplate = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}ets`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setEmailTemplateData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEmailTemplate = async (e) => {
    if (e.target.value === 0) {
      setEmailOnchange(false);
    } else {
      setEmailOnchange(true);
    }
    const validateconfig = {
      method: "GET",
      url: `${API_URL}et/${e.target.value}`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setEmailData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Email Template end

  const fetchStudentData = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}get-students?user_id=${userId}`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setStudentData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchStudentGroupData = async (tutorId="") => {

    let urlValue = `${API_URL}student-groups`;
    if(tutorId != ""){
      urlValue = `${API_URL}student-groups/?tutor_id=`+tutorId;
    }
    const validateconfig = {
      method: "GET",
      url: urlValue,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          console.log('setstudentGroupData : ',response.data.data)
          setStudentGroupData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTutorData = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}tutors`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setTutorData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchChargeCategory = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}chargecats`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setAllChargeCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchFamilies = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}family-accounts`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setAllFamilies(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchInvoices = async (id) => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}account-invoices/${id}`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setAccountInvoices(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchInvoicesByDate = async (date_from, date_to) => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}invoices?date_from=${date_from}&date_to=${date_to}`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setAllInvoicesByDate(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Availability start

  const allAvailabilityData = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}get-availabilities`,
    };
    await axios(config)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          setGetAvailabilityData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Availability end

  //Tutor

  const getTutor = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}tutors`,
    };
    await axios(config)
      .then((response) => {
        // console.log(response);
        if (response.data.success === true) {
          setAllTutors(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEvent = async (startDate, endDate, tutorId="all") => {
    setAllEvents([]);
    const config = {
      method: "GET",
      url: `${API_URL}events?start_date=${startDate}&end_date=${endDate}&tutorId=${tutorId}`,
    };
    await axios(config)
      .then((response) => {
        setAllEvents(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Calendar Events End

  // Event Category

  const fetchCategory = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}eventcats`,
    };
    await axios(config)
      .then((response) => {
        setAllCategory(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLocation = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}eventlocs`,
    };
    await axios(config)
      .then((response) => {
        setAllLocation(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTransactionsByFamily = async (id) => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}account-transactions/` + id,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          console.log("response-----------", response);
          setAllTransactionsByFamily(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTransactionsByDates = async (fromDate, toDate) => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}transactions?date_from=${fromDate}&date_to=${toDate}`,
    };
    await axios(validateconfig)
      .then((response) => {
        // console.log(response.data);
        if (response.data.success === true) {
          setAllTransactionsByDates(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchStudentAttendances = async (studentId) => {
    const config = {
      method: "GET",
      url: `${API_URL}student/`+ studentId +`/attendances`,
    };
    await axios(config)
      .then((response) => {
        setStudentAttendance(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //attendance-summery
  const fetchStudentAttendanceSummery = async (studentId) => {
    const config = {
      method: "GET",
      url: `${API_URL}student/`+ studentId +`/attendance-summery`,
    };
    await axios(config)
      .then((response) => {
        setStudentAttendanceSummery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetchStudentFamilyContact
  const fetchStudentFamilyContact = async (studentId) => {
    const config = {
      method: "GET",
      url: `${API_URL}student/`+ studentId +`/family-contacts`,
    };
    await axios(config)
      .then((response) => {
        setStudentFamilyContact(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetchRegions
  const fetchRegions = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}regions`,
    };
    await axios(config)
      .then((response) => {
        setAllRegions(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetchCurrencies
  const fetchCurrencies = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}currencies`,
    };
    await axios(config)
      .then((response) => {
        setAllCurrencies(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //fetchTimeZones
  const fetchTimeZones = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}timezones`,
    };
    await axios(config)
      .then((response) => {
        setAllTimeZones(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  // getAndSetDomainName
  const getAndSetDomainName  =  async() => {
    const res =  await getDomainName();
    setCentralPortalDomain(res?.data)
    sessionStorage.setItem("centralPortalDomain", res?.data);
  };

  const getCurrencyFormats = async () => {
    const config = {
      method: "GET",
      url: `${API_URL}business-settings/price/display-formats`,
    };
    await axios(config)
      .then((response) => {
        setAllCurrencyFormats(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <userDataContext.Provider
      value={{
        centralPortalDomain,
        getAndSetDomainName,
        fetchStudentFamilyContact,
        studentFamilyContact,
        fetchStudentAttendances,
        studentAttendance,
        fetchStudentAttendanceSummery,
        studentAttendanceSummery,
        fetchData,
        userData,
        setUserData,
        fetchTimeZones,
        allTimeZones,
        fetchRegions,
        allRegions,
        fetchCurrencies,
        allCurrencies,
        logOut,
        sidebarToggle,
        setSidebarToggle,
        emailTemplate,
        emailTemplateData,
        handleEmailTemplate,
        emailOnchange,
        emailData,
        setEmailData,
        getStudentStatus,
        statusList,
        token,
        setToken,
        fetchStudentData,
        fetchStudentGroupData,
        studentGroupData,
        studentData,
        tutorData,
        userId,
        setUserId,
        allAvailabilityData,
        getAvailabilityData,
        privileges,
        getPrivileges,
        allTutors,
        getTutor,
        fetchEvent,
        allEvents,
        fetchTutorData,
        fetchCategory,
        allCategory,
        fetchLocation,
        allLocation,
        fetchChargeCategory,
        allChargeCategory,
        allFamilies,
        setAllFamilies,
        fetchFamilies,
        fetchInvoices,
        dashboardData,
        getDashboardData,
        accountInvoices,
        allTransactionsByFamily,
        fetchTransactionsByFamily,
        allTransactionsByDates,
        fetchTransactionsByDates,
        fetchInvoicesByDate,
        allInvoicesByDate,
        allTransactionsByDates,
        fetchTransactionsByDates,
        isDarkMode,
        setIsDarkMode,
        theme,
        toggleTheme,
        allCurrencyFormats,
        getCurrencyFormats
      }}
    >
      {children}
    </userDataContext.Provider>
  );
};

export default AppContext;
