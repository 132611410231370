import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUserDataContext } from "../../../contextApi/userDataContext";

const GeneralSettings = ({ handleChange, formData, formSubmit }) => {
  const { t } = useTranslation();
  const { allCurrencyFormats, getCurrencyFormats } = useUserDataContext();
  useEffect(() => {
    getCurrencyFormats();
  }, []);

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingOne">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <strong>{t("General")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Event Scheduling")}</h3>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="preferences" className="formbold-form-label">
                    {t("Scheduling Conflicts")}
                  </label>
                  <br />
                  <div className="preference" style={{ fontSize: "15px" }}>
                    <input
                      type="checkbox"
                      name="scheduling_conflicts"
                      onChange={handleChange}
                      checked={
                        formData.scheduling_conflicts === 1 ? true : false
                      }
                    />
                    {t(
                      "Check for scheduling conflicts when adding/editing calendar events"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-body pt-0">
              <div className="student-properties-edit sec-acc p-1">
                <h3>{t("Naming Format")}</h3>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <div>
                    <label
                      htmlFor="tutorNameFormat"
                      className="formbold-form-label"
                    >
                      {t("Tutor Name Format")}
                    </label>
                  </div>
                  <div className="input-radio">
                    <input
                      type="radio"
                      value="{First} {Last}"
                      name="tutor_name_format"
                      onChange={handleChange}
                      checked={
                        formData.tutor_name_format === "{First} {Last}"
                          ? true
                          : false
                      }
                    />
                    {t("First Last")}
                    <input
                      type="radio"
                      value="{Last}, {First}"
                      name="tutor_name_format"
                      onChange={handleChange}
                      checked={
                        formData.tutor_name_format === "{Last}, {First}"
                          ? true
                          : false
                      }
                    />
                    {t("Last, First")}
                  </div>
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <div>
                    <label
                      htmlFor="studentNameFormat"
                      className="formbold-form-label"
                    >
                      {t("Student Name Format")}
                    </label>
                  </div>
                  <div className="input-radio">
                    <input
                      type="radio"
                      value="{First} {Last}"
                      name="student_name_format"
                      onChange={handleChange}
                      checked={
                        formData.student_name_format === "{First} {Last}"
                          ? true
                          : false
                      }
                    />
                    {t("First Last")}
                    <input
                      type="radio"
                      value="{Last}, {First}"
                      name="student_name_format"
                      onChange={handleChange}
                      checked={
                        formData.student_name_format === "{Last}, {First}"
                          ? true
                          : false
                      }
                    />
                    {t("Last, First")}
                  </div>
                </div>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <div>
                    <label
                      htmlFor="parentNameFormat"
                      className="formbold-form-label"
                    >
                      {t("Parent Name Format")}
                    </label>
                  </div>
                  <div className="input-radio">
                    <input
                      type="radio"
                      value="{First} {Last}"
                      name="parent_name_format"
                      onChange={handleChange}
                      checked={
                        formData.parent_name_format === "{First} {Last}"
                          ? true
                          : false
                      }
                    />
                    {t("First Last")}
                    <input
                      type="radio"
                      value="{Last}, {First}"
                      name="parent_name_format"
                      onChange={handleChange}
                      checked={
                        formData.parent_name_format === "{Last}, {First}"
                          ? true
                          : false
                      }
                    />
                    {t("Last, First")}
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-body pt-0">
              <div className="student-properties-edit sec-acc p-1">
                <h3>{t("Currency Format")}</h3>
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="currency_format" className="formbold-form-label">Currency Format</label>
                  <select name="currency_format" className="form-control" value={formData.currency_format} onChange={handleChange}>
                    <option value="" disabled>Select Currency Format</option>
                    {allCurrencyFormats.map((currency_format) => {
                      return (
                        <option data-label={currency_format} key={currency_format} value={currency_format} selected={currency_format == formData.currency_format}>
                          {currency_format}
                        </option>
                      );                    
                    })}
                  </select>
                </div>
              </div>
              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
