import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import study from "./assets/images/study.png";
import createAccount from "./assets/images/create-account.png";
import createInvoice from "./assets/images/create-invoice.png";
import addStudent from "./assets/images/add-student.png";
import scheduleEvent from "./assets/images/schedule-event.png";
import youGotIt from "./assets/images/got-it.png";
import eventIcon from "./assets/images/event.svg";
import paymentIcon from "./assets/images/payment.svg";
import projectIcon from "./assets/images/project.svg";
import activeIcon from "./assets/images/active-student.svg";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import LanguageOption from "../LanguageOption.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import UpcomingEvents from "./dashboard/UpcomingEvents.js"; // Adjust the path accordingly
import './assets/css/style.css';


const TutorDashboard = ({ dashboardData, accountType }) => {
  const { userData } = useUserDataContext();
  const { t } = useTranslation();
  return (
    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">
        <strong>
          {t("Let's get started,")} {userData?.first_name}!
        </strong>
      </h1>

      <div className="row all-step">
        <div className="col-xl-12 col-xxl-12 steps">
          <div className="row">
            <div className="col-auto">
              <div className="stat text-primary icon2">
                <Link
                  // className="dropdown-item"
                  to={"/students/add"}
                >
                  <img src={addStudent} alt="add-student-icon" style={{left: 22}}/>
                </Link>
              </div>
            </div>
            <div className="col mt-0">
              <h5 className="card-title">{t("Add Student")}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <div className="stat text-primary icon3">
                <Link className="dropdown-item" to={"/calendar"}>
                  <img src={scheduleEvent} alt="schedule-event-icon" style={{left: 22}}/>
                </Link>
              </div>
            </div>
            <div className="col mt-0">
              <h5 className="card-title">{t("Schedule Event")}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <div className="stat text-primary icon4">
              <Link to="/familiies-and-invoices/invoice/1" >
                  <img src={createInvoice} alt="create-invoice-icon" />
              </Link>
              </div>
            </div>
            <div className="col mt-0">
              <h5 className="card-title">{t("Create Invoice")}</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <div className="stat text-primary icon5">
                <img src={youGotIt} alt="you've got it" />
              </div>
            </div>
            <div className="col mt-0">
              <h5 className="card-title">{t("You've Got It!")}</h5>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 d-flex"></div>
      </div>

      <div className="accordion pb-4" id="dashboardAccordion" style={{ backgroundColor: '#f7f5ed', border: 'none' }}>
        <div className="accordion-item" style={{ border: 'none' }}>
          <h2 className="accordion-header" id="headingOne" style={{ backgroundColor: '#f7f5ed', borderBottom: '1px solid #dcdcdc' }}>
            <button
              className="accordion-button collapsed tutor-dashboard"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              style={{ backgroundColor: '#f7f5ed', border: 'none' }}
            >
              <h1 className="h3 mb-0">
                <strong>Category</strong>
              </h1>
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#dashboardAccordion"
          >
            {/* RRR */}
            <div className="custom-accordion-body">
              <div className="col-xl-12 col-xxl-12 d-flex">
                <div className="w-100">
                  <div className="row">
                    <div className="col-sm-4 pr-2">
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary eventIcon">
                                <img src={eventIcon} className="eventImg" />
                              </div>
                              <h5 className="card-title">
                                {t("Event left this week")}
                              </h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.eventLeftThisWeekCount ? dashboardData?.eventLeftThisWeekCount : "0"}</h1>
                          {/* <div className="mb-0">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -3.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary paymentIcon">
                                <img src={paymentIcon} className="paymentImg" />
                              </div>
                              <h5 className="card-title">
                                {t("Payment recieved this month")}
                              </h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.PaymentRecievedThisMonth ? dashboardData?.PaymentRecievedThisMonth : "0"}</h1>
                          {/* <div className="mb-0">
                          <span className="text-success">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            6.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card">
                        <div className="card-body db-card-body">
                          <div className="row">
                            <div className="col mt-0">
                              <div className="square text-primary activeIcon">
                                <img src={activeIcon} />
                              </div> 
                              <h5 className="card-title">{t("Active Students")}</h5>
                            </div>
                          </div>
                          <h1 className="mt-1 mb-3">{dashboardData?.activeStudentsCount ? dashboardData?.activeStudentsCount : "0"}</h1>
                          {/* <div className="mb-0">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -2.25%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* RRR */}
          </div>
        </div>
      </div>


      {/* Upcoming Events Component */}
      <div className="row mt-4">
        <div className="col-12">
          <UpcomingEvents 
          type={accountType}/>
        </div>
      </div>

      {/* <div className="row">
      <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
        <div className="card flex-fill w-100">
          <div className="card-header">
            <h5 className="card-title mb-0">Agenda</h5>
          </div>
          <div className="card-body d-flex">
            <div className="align-self-center w-100">
              <div className="py-3">
                <div className="chart chart-xs">
                  <canvas id="chartjs-dashboard-pie"></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default TutorDashboard;
